import { createContext, useContext, useEffect, useState } from "react";
import { API_GET, DBRequest } from "./api/api";
import Cookies from "universal-cookie";

const cookies = new Cookies();

// Create a context
const AppConfigContext = createContext();

export const AppConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);

  const fetchConfigJson = async () => {
    try {
      const response = await fetch("/config.json");
      const config = await response.json();
      fetchConfigApi(config);
    } catch (error) {
      console.error("Error fetching configuration:", error);
    }
  };

  function fetchConfigApi(config) {
    cookies.set("isLowPerformance", config.isLowPerformance || false, {
      path: "/",
    });

    const apiUrls = {
      API_PWFORGOT: `${config.apiUrl}/api/forgot-password`,
      API_PWRESET: `${config.apiUrl}/api/reset-password`,
      API_LOGIN: `${config.apiUrl}/api/login`,
      API_LOGOUT: `${config.apiUrl}/api/logout`,
      API_AUTH: `${config.apiUrl}/api/authorization`,
      API_URL: `${config.apiUrl}/api/${config.apiVersion}/`,
      API_MODULES: `${config.apiUrl}/api/${config.apiVersion}/modules/`,
    };
    // get global config from api
    DBRequest({
      config: apiUrls,
      path: "config",
      method: API_GET,
      onResponse: (res) => handleResponse(res, apiUrls, config),
      onError: (res) => handleError(res, apiUrls, config),
      noAuth: true,
    });
  }

  function handleResponse(res, apiUrls, config) {
    var apiConfig = {};
    res.data.forEach((configElement) => {
      var catConfig = {};
      configElement.items.forEach((configElementItem) => {
        if (configElementItem.key == "paymentOptions") {
          catConfig[configElementItem.key] = configElementItem;
        } else {
          catConfig[configElementItem.key] = configElementItem.value;
        }
      });
      apiConfig[configElement.category] = catConfig;
    });
    /*console.log("CONFIG", {
      ...config,
      ...apiUrls,
      ...apiConfig,
    });*/
    setConfig({
      ...config,
      ...apiUrls,
      ...apiConfig,
    });
  }

  function handleError(res, apiUrls, config) {
    setConfig({
      ...config,
      ...apiUrls,
    });
  }

  useEffect(() => {
    fetchConfigJson();
  }, []);

  return (
    <AppConfigContext.Provider value={{ config }}>
      {children}
    </AppConfigContext.Provider>
  );
};

// Custom hook to use the context
export const useAppConfig = () => {
  return useContext(AppConfigContext);
};
