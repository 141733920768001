import { Box, Collapse, Typography, useTheme } from '@mui/material';
import moment from 'moment';
import DateLabel from '../../../../components/label/DateLabel';
import { useTranslation } from 'react-i18next';
import { tokens } from '../../../../global/theme/tokens';
import { useEffect, useState } from 'react';
import { renderHours } from '../../../time-tracking/worktime';
import { TimeAccountCard } from '../../../time-tracking/TimeAccountCard';

export const TimeInfo = ({ time, format, prefix }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const [lastTime, setLastTime] = useState(null);

  useEffect(() => {
    if (time) setLastTime(time);
  }, [time]);

  const label = lastTime
    ? prefix
      ? `${prefix} ${moment(lastTime.date).format(format)}`
      : moment(lastTime.date).format(format)
    : '';

  const { holiday, sick, vacation, worked } = lastTime
    ? lastTime?.percentages
    : {};

  const deviation =
    lastTime?.totalTimeSeconds -
    (lastTime?.targetTimeSeconds +
      lastTime?.totalTimeSeconds * (vacation + holiday + sick));

  return (
    <Collapse in={Boolean(time)} className="w-full flex flex-col ">
      <Box
        className="w-full pt-2 pb-4 px-4 rounded-md mb-1 flex flex-col gap-4"
        sx={{
          bgcolor: colors.selected + '80',
        }}
      >
        <Box className="pt-2 flex justify-between items-center">
          <Typography variant="h4">
            {t(label)}{' '}
            <span className="opacity-50">
              <DateLabel date={lastTime?.date} />
            </span>
          </Typography>

          <Box className="flex justify-center">
            <TimeAccountCard
              size="small"
              label={t('deviation')}
              minutes={deviation / 60}
            />
          </Box>
        </Box>

        <Box className="flex justify-evenly flex-wrap">
          <TimeLabelElement
            src={'/assets/uploads/times/worked.webp'}
            label={t('Worked')}
            totalTime={lastTime?.totalTimeSeconds}
            percent={worked}
          />
          <TimeLabelElement
            src={'/assets/uploads/times/vacation.webp'}
            label={t('Vacation')}
            totalTime={lastTime?.totalTimeSeconds}
            percent={vacation}
          />
          <TimeLabelElement
            src={'/assets/uploads/times/sick.webp'}
            label={t('Sick')}
            totalTime={lastTime?.totalTimeSeconds}
            percent={sick}
          />
          <TimeLabelElement
            src={'/assets/uploads/times/holiday.webp'}
            label={t('Holiday')}
            totalTime={lastTime?.totalTimeSeconds}
            percent={holiday}
          />
        </Box>
      </Box>
    </Collapse>
  );
};

export const TimeLabelElement = ({
  src,
  label,
  totalTime,
  percent,
  hidePercent,
  format = 'hour',
  sufix = 'h',
  useFix,
}) => {
  var calcTime = Math.round(totalTime * percent * 100) / 100;

  if (format === 'hour') calcTime = calcTime / 60;

  return (
    <Box
      className="flex flex-col justify-center items-center"
      sx={{
        opacity: parseInt(calcTime) !== 0 ? 1 : 0.2,
        minWidth: '150px',
      }}
    >
      <img src={src} className=" w-[55px]" />
      <Typography
        variant="h3"
        textAlign="center"
        className="monospace"
        fontWeight={500}
      >
        {`${
          format === 'hour' ? renderHours(calcTime, useFix) : calcTime
        } ${sufix}`}
      </Typography>
      <Typography textAlign="center"></Typography>
      <Box>
        <Typography textAlign="center">
          {hidePercent
            ? label
            : `${label} (${parseFloat(percent * 100).toFixed(0)}%)`}
        </Typography>
      </Box>
    </Box>
  );
};
