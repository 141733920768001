import { useParams } from "react-router-dom";
import useFetch from "../../api/useFetch";
import EmployeeInfo from "./EmployeeInfo";
import { Box } from "@mui/material";
import Header from "../../components/special/Header";
import ExoAvatar from "../../components/exo/ExoAvatar";

export const ShowEmployee = ({ api, own }) => {
  const { id } = useParams();
  const { apiData, isLoading, setApiData } = useFetch(api || `users/${id}`);

  const name = apiData
    ? `${apiData.data.firstName} ${apiData.data.lastName}`
    : " ";

  function handleChange(updatedUser) {
    setApiData({ ...apiData, data: updatedUser });
  }

  return (
    <Box className="flex flex-col h-full">
      <Header
        title={
          <>
            <ExoAvatar
              picture={
                apiData &&
                apiData.data.picture &&
                apiData.data.picture.temporaryUrl
              }
              size="50px"
            />
            {name}
          </>
        }
      />
      <EmployeeInfo
        employeeInfo={apiData && apiData.data}
        isLoading={!apiData}
        onChange={handleChange}
        noHead
        own={own}
      />
    </Box>
  );
};
