import { createElement } from 'react';
import ExtendTimeCorrection from './extended-request-versions/ExtendTimeCorrection';
import ExtendTimeCompensation from './extended-request-versions/ExtendTimeCompensation';
import { ExtendVacation } from './extended-request-versions/ExtendVacation';
import TypeSkeleton from '../../components/form/form-fields/TypeSkeleton';
import CardGrid from '../../components/cards/layout/CardGrid';
import useFetch from '../../api/useFetch';
import { ExtendSick } from './extended-request-versions/ExtendSick';

const ExtendRequest = ({ request, onUpdate }) => {
  const { apiData } = useFetch(`employeerequests/${request.id}`);
  if (!apiData)
    return (
      <CardGrid>
        <TypeSkeleton n={4} />
      </CardGrid>
    );
  return createElement(activeTypes[request.type], {
    request: apiData.data,
    onUpdate: onUpdate,
  });
};

const activeTypes = {
  timeCorrection: ExtendTimeCorrection,
  timeCompensation: ExtendTimeCompensation,
  vacation: ExtendVacation,
  sick: ExtendSick,
};

export default ExtendRequest;
