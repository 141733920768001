import { Box, Dialog, IconButton, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import useFetch from "../../../api/useFetch";
import ExoTable from "../../../components/exo/ExoTable";
import { useTranslation } from "react-i18next";
import { ExpandAdvancedTurnoverTaxRate } from "./ExpandAdvancedTurnoverTaxRate";
import MoneyLabel from "../../../components/label/MoneyLabel";
import {
  ExoTableToolbar,
  ExoTableToolbarItem,
} from "../../../components/exo/ExoTableToolbar";
import { useState } from "react";
import ExoForm from "../../../components/exo/ExoForm";
import { API_GET, DBRequest, useConfig } from "../../../api/api";
import moment from "moment";
import Feedback from "../../../components/special/Feedback";
import ExoAccordion from "../../../components/exo/ExoAccordion";
import DateLabel from "../../../components/label/DateLabel";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router-dom";
import { tokens } from "../../../global/theme/tokens";

export default function AdvancedTurnoverTaxReturn() {
  const config = useConfig();
  const { t } = useTranslation();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const navigate = useNavigate();

  let initialTimespan = {
    year: moment().year(),
    month: moment().month() + 1,
    quarter: moment().quarter(),
    halfYear: moment().month() <= 5 ? 1 : 2,
    period: 12,
    selection: 1,
  };

  if (config?.accounting.accountingPeriod == 6)
    initialTimespan = {
      ...initialTimespan,
      selection: initialTimespan.halfYear,
    };

  if (config?.accounting.accountingPeriod == 3)
    initialTimespan = {
      ...initialTimespan,
      selection: initialTimespan.quarter,
    };
  if (config?.accounting.accountingPeriod == 1)
    initialTimespan = { ...initialTimespan, selection: initialTimespan.month };

  useEffect(() => {
    let startDate = null;
    let endDate = null;
    if (config?.accounting.accountingPeriod == 12) {
      startDate = moment().year(initialTimespan.year).startOf("year");
      endDate = moment().year(initialTimespan.year).endOf("year");
    }
    if (config?.accounting.accountingPeriod == 6) {
      startDate = moment()
        .year(initialTimespan.year)
        .month(initialTimespan.halfYear == 1 ? 0 : 6)
        .startOf("month");

      endDate = moment()
        .year(initialTimespan.year)
        .month(initialTimespan.halfYear == 1 ? 5 : 11)
        .endOf("month");
    }
    if (config?.accounting.accountingPeriod == 3) {
      startDate = moment()
        .year(initialTimespan.year)
        .quarter(initialTimespan.quarter)
        .startOf("quarter");

      endDate = moment()
        .year(initialTimespan.year)
        .quarter(initialTimespan.quarter)
        .endOf("quarter");
    }
    if (config?.accounting.accountingPeriod == 1) {
      startDate = moment()
        .year(initialTimespan.year)
        .month(initialTimespan.month - 1)
        .startOf("month");

      endDate = moment()
        .year(initialTimespan.year)
        .month(initialTimespan.month - 1)
        .endOf("month");
    }
    setSelectedTimeRange({
      start: startDate,
      end: endDate,
    });
  }, [config]);

  const [selectedTimeRange, setSelectedTimeRange] = useState({
    start: null,
    end: null,
  });

  const [dateIsLoading, setDateIsLoading] = useState(false);

  const { apiData, setApiData, isLoading } = useFetch(
    `advanced-turnover-tax-return?year=${initialTimespan.year}&period=${config?.accounting.accountingPeriod}&selection=${initialTimespan.selection}`
  );

  const [open, setOpen] = useState(false);
  const [timeSpanDataCollection, setTimeSpanDataCollection] =
    useState(initialTimespan);
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: "info",
    alertText: "test",
  });

  const columns = [
    { key: "label" },
    {
      header: t("Tax Amount"),
      key: "totalTax",
      tdAlign: "center",
      cell: ({ row }) => (
        <Typography className=" monospace">
          <MoneyLabel money={row.totalTax} />
        </Typography>
      ), // custom xml for cells in the column. you get the row as object },
    },
  ];

  const initialFields = [
    {
      label: t("Year"),
      key: "year",
      type: "date",
      format: "YYYY",
      views: ["year"],
    },
    {
      label: t("Half Year"),
      key: "halfYear",
      type: "customSelect",
      options: [
        { label: "H1", value: 1 },
        { label: "H2", value: 2 },
      ],
    },
    {
      label: t("Quarter"),
      key: "quarter",
      type: "customSelect",
      options: [
        { label: "Q1", value: 1 },
        { label: "Q2", value: 2 },
        { label: "Q3", value: 3 },
        { label: "Q4", value: 4 },
      ],
    },
    {
      label: t("Month"),
      key: "month",
      type: "date",
      format: "MM",
      views: ["month"],
    },
  ];

  const [fields, setFields] = useState(initialFields);

  useEffect(() => {
    let newFields = [
      {
        label: t("Year"),
        key: "year",
        type: "date",
        format: "YYYY",
        views: ["year"],
      },
    ];

    if (config?.accounting.accountingPeriod == 1) {
      newFields.push({
        label: t("Month"),
        key: "month",
        type: "date",
        format: "MM",
        views: ["month"],
      });
    }
    if (config?.accounting.accountingPeriod == 3) {
      newFields.push({
        label: t("Quarter"),
        key: "quarter",
        type: "customSelect",
        options: [
          { label: "Q1", value: 1 },
          { label: "Q2", value: 2 },
          { label: "Q3", value: 3 },
          { label: "Q4", value: 4 },
        ],
      });
    }
    if (config?.accounting.accountingPeriod == 6) {
      newFields.push({
        label: t("Half Year"),
        key: "halfYear",
        type: "customSelect",
        options: [
          { label: "H1", value: 1 },
          { label: "H2", value: 2 },
        ],
      });
    }

    setFields(newFields);
  }, [config]);

  function handleNewTimeSpan(data) {
    setDateIsLoading(true);
    let newTimeSpan = {
      year: data.year,
      month: data.month,
      quarter: data.quarter,
      halfYear: data.halfYear,
      period: config.accounting.accountingPeriod,
      selection: 1,
    };
    if (config?.accounting.accountingPeriod == 6)
      newTimeSpan = { ...newTimeSpan, selection: data.halfYear };
    if (config?.accounting.accountingPeriod == 3)
      newTimeSpan = { ...newTimeSpan, selection: data.quarter };
    if (config?.accounting.accountingPeriod == 1)
      newTimeSpan = { ...newTimeSpan, selection: data.month };

    setTimeSpanDataCollection(newTimeSpan);

    let startDate = null;
    let endDate = null;
    if (config?.accounting.accountingPeriod == 12) {
      startDate = moment().year(newTimeSpan.year).startOf("year");
      endDate = moment().year(newTimeSpan.year).endOf("year");
    }
    if (config?.accounting.accountingPeriod == 6) {
      startDate = moment()
        .year(newTimeSpan.year)
        .month(newTimeSpan.halfYear == 1 ? 0 : 6)
        .startOf("month");

      endDate = moment()
        .year(newTimeSpan.year)
        .month(newTimeSpan.halfYear == 1 ? 5 : 11)
        .endOf("month");
    }
    if (config?.accounting.accountingPeriod == 3) {
      startDate = moment()
        .year(newTimeSpan.year)
        .quarter(newTimeSpan.quarter)
        .startOf("quarter");

      endDate = moment()
        .year(newTimeSpan.year)
        .quarter(newTimeSpan.quarter)
        .endOf("quarter");
    }
    if (config?.accounting.accountingPeriod == 1) {
      startDate = moment()
        .year(newTimeSpan.year)
        .month(newTimeSpan.month - 1)
        .startOf("month");

      endDate = moment()
        .year(newTimeSpan.year)
        .month(newTimeSpan.month - 1)
        .endOf("month");
    }

    setSelectedTimeRange({ start: startDate, end: endDate });

    DBRequest({
      config,
      path: newTimeSpan.selection
        ? `advanced-turnover-tax-return?year=${newTimeSpan.year}&selection=${newTimeSpan.selection}&period=${newTimeSpan.period}`
        : `advanced-turnover-tax-return?year=${newTimeSpan.year}&period=${newTimeSpan.period}`,
      method: API_GET,
      onResponse: handleResponse,
      onFeedback: handleFeedback,
    });
  }

  function handleResponse(data) {
    setApiData(data);
    setDateIsLoading(false);

    setOpen(false);
  }

  function handleFeedback(text, type) {
    if (type !== "success")
      setAlertState({
        alertOpen: true,
        alertText: text,
        alertType: type,
      });
  }

  function generateInvoiceList(data) {
    let invoices = [];
    data.map((el, index) =>
      invoices.push(
        <tr key={data.key + "Invoice" + index}>
          <td className="w-[15%]">
            <DateLabel date={el.date} />
          </td>
          <td className="w-[20%]">
            <Typography>{el.nr}</Typography>
          </td>
          <td className="w-[40%]">
            <Typography>{el.title}</Typography>
          </td>
          <td className="w-[10%]">
            <Typography className=" monospace">
              <MoneyLabel money={el.amount} />
            </Typography>
          </td>
          <td className="w-[10%]">
            <Typography className=" monospace text-right">
              <MoneyLabel money={el.taxAmount} />
            </Typography>
          </td>
          <td className="w-[5%]">
            <IconButton onClick={() => navigate(`/invoices/${el.id}`)}>
              <OpenInNewIcon />
            </IconButton>
          </td>
        </tr>
      )
    );
    return invoices;
  }

  function generateAccordionData(data) {
    let elements = [];
    data.map((el, index) =>
      elements.push({
        title: el.label,

        customSummary: (
          <Box className="flex gap-5 justify-between w-full mr-5">
            <Box className="flex gap-3 w-[50%] items-center">
              <Typography fontSize={10}>{el.row}</Typography>
              <Typography>{el.label}</Typography>
            </Box>

            <Box className="w-[50%] flex flex-col md:flex-row justify-between gap-[20px]">
              <Box className="flex items-center gap-1">
                {el.position ? (
                  <Typography className="w-[20px] text-center" fontWeight={800}>
                    {el.position}
                  </Typography>
                ) : (
                  <Box className="w-[20px]" />
                )}
                <Box
                  className="border-4 rounded-lg flex gap-2 w-[125px] h-fit justify-end items-center"
                  sx={{
                    background: colors.selected,
                    borderColor: colors.selected,
                  }}
                >
                  <MoneyLabel money={el.nettoAmount} />
                </Box>
              </Box>
              <Box className="flex items-center gap-1">
                {el.taxPosition ? (
                  <Typography className="w-[20px] text-center" fontWeight={800}>
                    {el.taxPosition}
                  </Typography>
                ) : (
                  <Box className="w-[20px]" />
                )}
                <Box
                  className="border-4 rounded-lg flex gap-2 w-[125px] h-fit justify-end items-center"
                  sx={{
                    background: colors.selected,
                    borderColor: colors.selected,
                  }}
                >
                  <MoneyLabel money={el.taxAmount} />
                </Box>
              </Box>
            </Box>
          </Box>
        ),

        content: (
          <table
            key={data.key + "FieldContent" + index}
            className="w-full ml-4"
          >
            <tr>
              <th className="text-left">{t("Date")}</th>
              <th className="text-left">{t("Nr")}</th>
              <th className="text-left">{t("Title")}</th>
              <th className="text-left">{t("Netto")}</th>
              <th className="text-left">{t("Tax")}</th>
              <th></th>
            </tr>
            {generateInvoiceList(el.items)}
          </table>
        ),
      })
    );
    return elements;
  }

  /*return (
    <Box className="w-full flex flex-col gap-0 h-full">
    <ExoTableToolbar>
    <ExoTableToolbarItem
    text={t("Timespan")}
    onClick={() => setOpen(true)}
    />
    </ExoTableToolbar>
    <ExoTable
    data={apiData}
    columns={columns}
    isLoading={isLoading}
    extendElement={({ row }) => <ExpandAdvancedTurnoverTaxRate row={row} />}
    />
    <Dialog open={open}>
    <Box className="p-4">
    <ExoForm
    startDataCollection={timeSpanDataCollection}
    className="flex flex-col gap-4"
    header={`${t("Set the Timespan")}`}
    fields={fields}
    onCancle={() => setOpen(false)}
    submitText={t("Apply")}
    onSubmit={handleNewTimeSpan}
    noDifferences
    />
    </Box>
    <Feedback setState={setAlertState} state={alertState} />
    </Dialog>
    </Box>
    );
    }*/

  return (
    <Box className="w-full flex flex-col gap-0 h-full">
      <ExoTableToolbar>
        <ExoTableToolbarItem
          text={t("Timespan")}
          onClick={() => setOpen(true)}
        />
        <Typography>
          <DateLabel date={selectedTimeRange.start} />
          {" - "}
          <DateLabel date={selectedTimeRange.end} />
        </Typography>
      </ExoTableToolbar>

      {apiData?.data.map((el, index) => (
        <Box key={"wrapper" + index} className="mb-5 w-full">
          <Typography
            variant="h2"
            fontSize={18}
            fontWeight={500}
            key={"titel" + index}
          >
            {el.label}
          </Typography>
          <Box className="w-full mt-3">
            <ExoAccordion
              key={"data" + index}
              elements={generateAccordionData(el.fields)}
            />
          </Box>
        </Box>
      ))}

      <Dialog open={open}>
        <Box className="p-4">
          <ExoForm
            isLoading={dateIsLoading}
            startDataCollection={timeSpanDataCollection}
            className="flex flex-col gap-4"
            header={`${t("Set the Timespan")}`}
            fields={fields}
            onCancle={() => setOpen(false)}
            submitText={t("Apply")}
            onSubmit={handleNewTimeSpan}
            noDifferences
          />
        </Box>
        <Feedback setState={setAlertState} state={alertState} />
      </Dialog>
    </Box>
  );
}
