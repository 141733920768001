import React, { useEffect, useRef, useState } from 'react';
import SingleFileUpload from '../../../components/upload/SingleFileUpload';
import { Box } from '@mui/material';
import ExoForm from '../../../components/exo/ExoForm';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { DBUploadData, useConfig } from '../../../api/api';
import moment from 'moment';

export const UploadPayroll = ({ debug, onResponse, onClose, payroll }) => {
  const { t } = useTranslation();
  const config = useConfig();

  const exoFormRef = useRef(null);
  const singeUploadRef = useRef(null);
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  function handleUpload(rawData) {
    const formData = new FormData();

    if (payroll && payroll.file) {
      if (file && !file.id) {
        if (Object.keys(file).length) {
          formData.append(`file`, file);
        } else {
          formData.append(`file`, null);
        }
      }
    } else {
      if (file && Object.keys(file).length > 0) formData.append(`file`, file);
    }

    if (rawData.amount) formData.append(`amount`, rawData.amount);
    if (rawData.month) {
      const startDate = moment(rawData.month, 'MM/YYYY')
        .startOf('M')
        .format('YYYY-MM-DD');
      const endDate = moment(rawData.month, 'MM/YYYY')
        .endOf('M')
        .format('YYYY-MM-DD');

      formData.append(`startDate`, startDate);
      formData.append(`endDate`, endDate);
    }
    if (rawData.user) formData.append(`user`, JSON.stringify(rawData.user));
    if (rawData.transactions)
      formData.append(`transactions`, JSON.stringify(rawData.transactions));

    if (rawData.voluntarySocialBenefits)
      formData.append(
        `voluntarySocialBenefits`,
        JSON.stringify(rawData.voluntarySocialBenefits)
      );
    if (rawData.incomeBrutto)
      formData.append(`incomeBrutto`, JSON.stringify(rawData.incomeBrutto));
    if (rawData.incomeNetto)
      formData.append(`incomeNetto`, JSON.stringify(rawData.incomeNetto));
    if (rawData.incomeTax)
      formData.append(`incomeTax`, JSON.stringify(rawData.incomeTax));

    if (payroll && payroll.id) formData.append('_method', 'PATCH');
    const path = payroll && payroll.id ? `payrolls/${payroll.id}` : 'payrolls';

    setIsLoading(true);
    DBUploadData({
      config,
      path,
      formData: formData,
      onResponse,
      onError: () => setIsLoading(false),
    });
  }

  const validationSchema = Yup.object().shape({
    incomeTax: Yup.string().required(t('Is required')),
    incomeBrutto: Yup.string().required(t('Is required')),
    incomeNetto: Yup.string().required(t('Is required')),

    month: Yup.string().required(t('Is required')),
    user: Yup.object().shape({
      id: Yup.string().required(t('Is required')),
    }),
  });

  const fields = [
    {
      label: `${t('Transactions')} (${t('optional')})`,
      type: 'transactionArray',
      key: 'transactions',
    },
    {
      label: t('Employee'),
      type: 'user',
      key: 'user',
    },
    {
      label: t('Month'),
      type: 'date',
      key: 'month',
      views: ['month'],
      format: 'MM/YYYY',
    },
    {
      label: t('Voluntary Social Benefits'),
      type: 'money',
      key: 'voluntarySocialBenefits',
    },
    {
      label: t('Income Tax'),
      type: 'money',
      key: 'incomeTax',
    },
    {
      label: t('Income Brutto'),
      type: 'money',
      key: 'incomeBrutto',
    },
    {
      label: t('Income Netto'),
      type: 'money',
      key: 'incomeNetto',
    },
    {
      label: `${t('Comment')} (${t('optional')})`,
      type: 'description',
      key: 'comment',
    },
  ];

  const [usedValues, setUsedValues] = useState(null);
  useEffect(() => {
    if (payroll)
      setUsedValues({
        ...payroll,
        month: moment(payroll.startDate).format('MM/YYYY'),
      });
  }, [payroll]);

  // preload file
  useEffect(() => {
    if (payroll && payroll.file) {
      const newFileData = {
        id: payroll.file.id,
        lastModified: 0,
        name: payroll.file.title,
        path: payroll.file.temporaryUrl,
        size: payroll.file.fileSize,
        type: payroll.file.mimeType,
        webkitRelativePath: '',
      };
      singeUploadRef.current.setFileData(newFileData);
      setFile(newFileData);
    }
  }, [payroll]);

  // handle PDF preview width
  const previewContainerRef = useRef(null);
  const [previewWidth, setPreviewWidth] = useState('100%');
  const handleResize = () => {
    // Update containerWidth when the container is resized
    if (previewContainerRef.current) {
      const containerWidth = previewContainerRef.current.offsetWidth;
      setPreviewWidth(containerWidth);
    }
  };
  useEffect(() => {
    // Attach event listener for window resize
    window.addEventListener('resize', handleResize);

    // Initial setup
    handleResize();

    // Cleanup the event listener when the component is unmounted
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [previewContainerRef]);

  return (
    <Box
      className="w-full h-full overflow-hidden overflow-y-auto flex flex-col-reverse lg:flex-row gap-2 relative px-2 py-4 lg:px-2 lg:py-2 mx-auto"
      sx={{
        maxWidth: '1500px',
      }}
    >
      <Box
        ref={previewContainerRef}
        className="col-span-1 lg:col-span-2 w-full py-10 lg:max-w-[calc(100vw-550px)]"
      >
        <Box
          className="flex flex-col items-center justify-center overflow-x-hidden overflow-y-auto h-96 lg:h-full w-full relative"
          sx={{
            minHeight: '300px',
            width: previewWidth,
          }}
        >
          <SingleFileUpload
            debug={debug}
            ref={singeUploadRef}
            setFile={setFile}
            uploadedMedia={file}
          />
        </Box>
      </Box>
      <Box className="col-span-1 lg:col-span-3 flex flex-col gap-4 p-0 lg:p-1 rounded-lg max-w-full min-w-[unset] w-full lg:min-w-[550px] lg:max-w-[550px]">
        <ExoForm
          debug={debug}
          ref={exoFormRef}
          validationSchema={validationSchema}
          startDataCollection={usedValues}
          noDifferences={true}
          fields={fields}
          onSubmit={handleUpload}
          header={t('New Payroll')}
          onCancle={onClose}
          useStartDataOnReset
          submitText={payroll ? t('Update') : t('Create')}
          isNotValid={!Boolean(file && file.name)}
        />
      </Box>
    </Box>
  );
};
