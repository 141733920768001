import { Box, Dialog, IconButton, Tooltip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExoForm from "../../ExoForm";
import DateTimeLabel from "../../../label/DateTimeLabel";
import MoreTimeIcon from "@mui/icons-material/MoreTime";
import { API_PATCH, DBRequest, useConfig } from "../../../../api/api";
import { ProjectUserStack } from "../ProjectUserStack";
import { useRef, useState } from "react";
import * as Yup from "yup";
import FilePreviewCard from "../../../cards/FilePreviewCard";

const WPEToDoElementPopup = ({
  element,
  open,
  onClose,
  onResponse,
  onDelete,
  wpeId,
  pid,
}) => {
  const { t } = useTranslation();

  const config = useConfig();

  const popupRef = useRef();

  const [file, setFile] = useState(element.file);

  const [users, setUsers] = useState(element.users);

  const validationSchema = {
    name: Yup.string().required(),
  };

  const fields = [
    { label: t("Title"), key: "name", type: "text" },
    { label: t("Description"), key: "description", type: "richText" },
    {
      label: t("Priority"),
      key: "priority",
      type: "customSelect",
      options: [
        //{ label: t("Default"), value: 0 },
        { label: t("Low"), value: 1 },
        { label: t("Medium"), value: 2 },
        { label: t("High"), value: 3 },
      ],
    },
    { label: t("Deadline"), key: "deadline", type: "date" },
    { label: t("File"), key: "file", type: "image" },
  ];

  function handleSubmit() {
    let newTask = popupRef.current.dataCollection;
    newTask = { ...newTask, priority: newTask.priority ? newTask.priority : 0 };
    DBRequest({
      config,
      path: `work-packages/elements/list/tasks/${element.id}`,
      method: API_PATCH({ ...newTask, users: users }),
      onResponse: handleResponse,
    });
  }

  function handleResponse(data) {
    onResponse(data);
    onClose();
  }

  function handleMemberChange(newMembers) {
    setUsers(newMembers.users);
  }

  function onChange(data) {
    if (data.file) {
      const newFile = Object.keys(data.file).length === 0 ? null : data.file;

      setFile(newFile);
    } else {
      setFile(null);
    }
  }

  return (
    <Dialog
      className="relative"
      open={open}
      onClose={handleSubmit}
      sx={{
        "& .MuiPaper-root": {
          "@media (max-width: 768px)": {
            maxWidth: "100%",
            width: "100%",
          },
        },
      }}
    >
      <Box className="md:w-[50vw] m-5 mb-3 relative">
        <Box className="flex flex-col md:flex-row items-start md:items-center md:justify-between">
          <Box>
            <Typography variant="h3">{t("Edit Task")}</Typography>
            <Tooltip
              title={t("Date created")}
              className="flex gap-1 items-center my-2"
            >
              <MoreTimeIcon fontSize="small" />
              <Typography variant="h6">
                <DateTimeLabel dateTime={element.createdAt} />
              </Typography>
            </Tooltip>
          </Box>
          <ProjectUserStack
            changeApi={`tasks/${element.id}/members`}
            editmode={true}
            users={users || []}
            //api={"users"}
            projectId={pid}
            onChange={handleMemberChange}
            noLabel={true}
          />
        </Box>
        <ExoForm
          ref={popupRef}
          fields={fields}
          startDataCollection={element}
          onSubmit={handleSubmit}
          validationSchemaMap={validationSchema}
          noControls={true}
          onChange={onChange}
        />
        {file && (
          <Box className="mt-4">
            <FilePreviewCard file={file} />
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export default WPEToDoElementPopup;
