import { Box, Button, Skeleton, Typography, useTheme } from '@mui/material';
import { tokens } from '../../../global/theme/tokens';
import { useTranslation } from 'react-i18next';
import useFetch from '../../../api/useFetch';
import TemplatePreview from '../../templates/TemplatePreview';
import { useEffect } from 'react';
import SkeletonCard from '../../cards/SkeletonCard';

export const TypeTemplate = ({
  field,
  dataCollection,
  errors = {},
  updateValidationOnChange,
  onOnlyOne,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { apiData } = useFetch(
    `templates?templateType[eq]=${
      `${field.templateType}_${
        dataCollection[field.recipientTypeKey || 'recipientType'] || 'company'
      }` ||
      `invoice_${
        dataCollection[field.recipientTypeKey || 'recipientType'] || 'company'
      }`
    }`
  );

  useEffect(() => {
    if (!apiData) return;
    var data = [];
    apiData.data.forEach((element) => {
      if (!element.isHidden) data.push(element);
    });

    if (!dataCollection[field.key] && data[0]) {
      if (data.length === 1) onOnlyOne();
      return handleChange(data[0].id, data[0].metaData);
    }
    const selectedTemplate = apiData.data.find(
      (obj) => obj.id === dataCollection[field.key]
    );
    if (selectedTemplate) {
      if (data.length === 1) onOnlyOne();
      handleChange(selectedTemplate.id, selectedTemplate.metaData);
    }
  }, [apiData]);

  const { t } = useTranslation();

  function handleChange(templateId, metaData) {
    updateValidationOnChange(
      field.key,
      { [field.key]: templateId, templateMetaData: metaData },
      true
    );
  }

  return (
    <Box className="h-full overflow-y-auto">
      <Box className="flex flex-wrap gap-4">
        {apiData ? (
          <>
            {apiData.data.map(
              (template) =>
                !template.isHidden && (
                  <Button
                    key={template.id}
                    onClick={() => handleChange(template.id, template.metaData)}
                  >
                    <Box
                      className=" w-full rounded-lg overflow-hidden flex flex-col gap-2 pb-3 px-2 pt-2 justify-center items-center"
                      sx={{
                        backgroundColor:
                          dataCollection[field.key] === template.id
                            ? colors.selected
                            : colors.card,
                      }}
                    >
                      <Box
                        className="overflow-hidden flex mx-auto relative w-full justify-center"
                        sx={{
                          height: `${450}px`,
                          width: `${450 / 1.41}px`,
                          maxWidth: '100%',
                        }}
                      >
                        <TemplatePreview data={template} paginate={false} fit />
                      </Box>

                      <Typography variant="h4">{t(template.name)}</Typography>
                    </Box>
                  </Button>
                )
            )}
          </>
        ) : (
          <>
            <SkeletonCard
              sx={{
                height: `${450}px`,
                width: `${450 / 1.41}px`,
                maxWidth: '100%',
              }}
            />
            <SkeletonCard
              sx={{
                height: `${450}px`,
                width: `${450 / 1.41}px`,
                maxWidth: '100%',
              }}
            />
            <SkeletonCard
              sx={{
                height: `${450}px`,
                width: `${450 / 1.41}px`,
                maxWidth: '100%',
              }}
            />
          </>
        )}
      </Box>
    </Box>
  );
};
