import { useTranslation } from "react-i18next";
import ExoTabs from "../../components/exo/ExoTabs";
import Header from "../../components/special/Header";
import TimesheetsTab from "./timesheets/TimesheetsTab";
import AccountingTab from "./accounting/AccountingTab";
import AccountStatementTab from "./account-statement/AccountStatementTab";
import AdvancedTurnoverTaxReturn from "./advance-turnover-tax-return/AdvancedTurnoverTaxReturn";
import DatevExport from "./datev-exports/DatevExport";

const TaxConsultant = () => {
  const { t } = useTranslation();
  const tabs = [
    {
      label: t("Timesheets"),
      content: <TimesheetsTab />,
    },
    {
      label: t("Accounting"),
      content: <AccountingTab />,
    },
    { label: t("Account Statements"), content: <AccountStatementTab /> },
    {
      label: t("Advanced Turnover Tax Return"),
      content: <AdvancedTurnoverTaxReturn />,
    },
    /*{
      label: t("DATEV Export"),
      content: <DatevExport />,
    },*/
  ];

  /*
    {
      label: t("Finance Reports"),
      content: <Box>TEST2</Box>,
    },
    {
      label: t("Social Insurance"),
      content: <Box>TEST2</Box>,
    },
    {
      label: t("Input Tax"),
      content: <Box>TEST2</Box>,
    },
    {
      label: t("Tax Return"),
      content: <Box>TEST2</Box>,
    },*/

  return (
    <>
      <Header title={t("Tax Consultant")} />
      <ExoTabs orientation="vertical" tabs={tabs} />
    </>
  );
};

export default TaxConsultant;
