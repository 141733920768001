import { Box, Divider, Typography, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";
import BankAccount from "./BankAccount";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import MoneyLabel from "../../components/label/MoneyLabel";
import { ProjectAddElementButton } from "../../components/exo/project/ProjectAddElementButton";
import AddIcon from "@mui/icons-material/Add";
import ExoBankAccounts from "../../components/exo/ExoBankAccounts";
import ExoDialog from "../../components/exo/ExoDialog";
import NewBankAccount from "../../components/new/NewBankAccount";

export default function BankAccountSelector({
  accounts,
  handleCreatedAccount,
}) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [total, setTotal] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (accounts && accounts.length == 1) {
      navigate(`/bank-accounts/${accounts[0].id}`);
    }

    let tmp = 0;
    accounts?.forEach((element) => {
      tmp += parseFloat(element.balance);
    });
    setTotal(tmp);
  }, [accounts]);

  return (
    <Box
      className="w-full rounded-xl m-auto backdrop-blur-lg"
      sx={{
        maxWidth: "900px",
        //backgroundColor: colors.glass,
      }}
    >
      <Box className="p-3">
        <Box
          className="p-3 w-full h-full rounded-lg backdrop-blur-lg transition-transform flex justify-between items-center"
          sx={{
            backgroundColor: colors.glass,
          }}
        >
          <Typography variant="h4">{t("Total")}</Typography>
          <Typography
            noWrap
            variant="h5"
            textAlign="right"
            fontWeight={600}
            sx={{
              minWidth: "100px",
              color: total >= 0 ? colors.success : colors.error,
            }}
          >
            <MoneyLabel money={total} />
          </Typography>
        </Box>
      </Box>
      <Divider />
      <Box className="w-full h-full p-3 flex flex-col gap-2 overflow-y-auto overflow-x-hidden">
        {accounts && accounts.length == 0 && (
          <Box
            className="rounded-lg"
            sx={{
              backgroundColor: colors.glass,
            }}
          >
            <ProjectAddElementButton
              onClick={() => {
                setOpen(true);
              }}
            >
              <AddIcon />
            </ProjectAddElementButton>
          </Box>
        )}

        {accounts &&
          accounts.map((e) => (
            <BankAccount
              key={e.iban}
              onClick={() => navigate(`/bank-accounts/${e.id}`)}
              bank={e}
            />
          ))}
      </Box>

      <ExoDialog open={open} limitWidth>
        <NewBankAccount
          onClose={() => setOpen(false)}
          onCreate={handleCreatedAccount}
        />
      </ExoDialog>
    </Box>
  );
}
