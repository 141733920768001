import { Typography, useTheme } from '@mui/material';
import { chart, tokens } from '../../global/theme/tokens';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { Box } from '@mui/material';
import nivoChartTheme from '../../global/theme/nivoChartTheme';
import generateShades from '../../global/theme/ColorShades';
import { renderHours } from '../../scenes/time-tracking/worktime';

const RadialChart = ({ data }) => {
  const theme = useTheme();
  const chartTheme = nivoChartTheme(
    theme.palette.mode,
    theme.palette.colorTheme
  );
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const chartColors = generateShades(colors.info, 3.3, 4);

  return (
    <Box
      className="relative z-20 w-full h-full aspect-square"
      sx={{
        '& rect': {
          fill: 'transparent!important',
        },
      }}
    >
      <ResponsiveRadialBar
        data={data}
        tooltip={TooltipTest}
        theme={chartTheme}
        startAngle={-130}
        endAngle={130}
        innerRadius={0.85}
        padding={0}
        valueFormat=" >-,.0~r"
        margin={{ top: 0, right: 5, bottom: -15, left: 5 }}
        colors={chartColors.reverse()}
        borderColor={{
          from: 'color',
          modifiers: [['darker', '0']],
        }}
        enableTracks={false}
        enableRadialGrid={false}
        enableCircularGrid={false}
        radialAxisStart={null}
        circularAxisOuter={null}
        labelsSkipAngle={0}
        legends={[]}
        animate={false}
      />
    </Box>
  );
};

export default RadialChart;

const TooltipTest = ({ bar }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { category, value } = bar;
  return (
    <Box
      className=" backdrop-blur-lg px-3 py-1 rounded-md"
      sx={{ bgcolor: colors.card }}
    >
      <Typography>{`${category} - ${renderHours(value)}h`}</Typography>
    </Box>
  );
};
