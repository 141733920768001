import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import FormHelperText from "../FormHelperText";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const TypeConfigCustomMultiSelect = ({
  updateValidationOnChange,
  dataCollection,
  field,
  errors,
  startDataCollection,
}) => {
  const [value, setValue] = useState([]);
  const { t } = useTranslation();
  function handleChange(e) {
    updateValidationOnChange(field.key, JSON.stringify(e.target.value));
    setValue(e.target.value);
  }

  useEffect(() => {
    if (value.length > 0) return;
    setValue(JSON.parse(startDataCollection[field.key]) || []);
  }, [startDataCollection]);

  useEffect(() => {
    setValue(JSON.parse(dataCollection[field.key]) || []);
  }, [dataCollection[field.key]]);

  return (
    <FormControl fullWidth>
      <InputLabel id="origin-select-label">{field.label}</InputLabel>
      <Select
        labelId="origin-select-label"
        id="origin-select"
        value={value}
        label={field.label}
        onChange={handleChange}
        error={Boolean(errors[field.key])}
        multiple
      >
        {field.options &&
          field.options.map((option, index) => (
            <MenuItem key={index} value={option.value}>
              {t(option.label)}
            </MenuItem>
          ))}
      </Select>
      <FormHelperText
        error={Boolean(errors[field.key])}
        helperText={errors[field.key]}
      />
    </FormControl>
  );
};

export default TypeConfigCustomMultiSelect;
