import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { WPEContainer } from "../WPEContainer";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { WPEToDoElement } from "../../../../../components/exo/project/work-package-elements/WPEToDoElement";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import useFetch from "../../../../../api/useFetch";
import { updateOrCreate } from "../../../../../components/special/updateOrCreate";
import { useTranslation } from "react-i18next";
import NewWPEToDoElement from "../../../../../components/exo/project/work-package-elements/NewWPEToDoElement";
import { tokens } from "../../../../../global/theme/tokens";
import DeleteIcon from "@mui/icons-material/Delete";
import { Droppable } from "react-beautiful-dnd";
import { API_DELETE, DBRequest, useConfig } from "../../../../../api/api";

export const WPETypeToDoList = forwardRef(
  ({ element, onChange, onDelete, isDragging, draggingStyle, pid }, ref) => {
    const { t } = useTranslation();

    const config = useConfig();

    const theme = useTheme();
    const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

    const { apiData, setApiData } = useFetch(
      `work-packages/elements/list/${element.id}`
    );

    useImperativeHandle(ref, () => ({
      apiData: apiData,
      setApiData: setApiData,
    }));

    const [isCheckedVisible, setisCheckedVisible] = useState(false);
    const [hoverText, setHoverText] = useState("Hide completed tasks");

    const [completedCount, setCompletedCount] = useState(0);
    const [openNewToDo, setOpenNewToDo] = useState(false);

    function updateCompletedCount(tasks) {
      tasks = tasks.filter((element) => element.status != null);
      setCompletedCount(
        tasks.reduce((sum, element) => sum + element.status, 0)
      );
    }

    useEffect(() => {
      if (apiData) updateCompletedCount(apiData.data.tasks);
    }, [apiData]);

    function handleToggleVisibility() {
      setisCheckedVisible(!isCheckedVisible);
      if (isCheckedVisible) {
        setHoverText("Show completed tasks");
      } else setHoverText("Hide completed tasks");
    }

    function handleAddToDo() {
      setOpenNewToDo(true);
    }

    function handleNewToDo(newTask) {
      const updatedTasks = apiData.data.tasks.map((el) => ({
        ...el,
        sortIndex: el.sortIndex + 1,
      }));

      setApiData({
        ...apiData,
        data: { ...apiData.data, tasks: [newTask.data, ...updatedTasks] },
      });

      setOpenNewToDo(false);
    }

    function handleChange(updatedTask) {
      const updatedTasks = updateOrCreate(apiData.data.tasks, updatedTask);
      const dbTasks = updatedTasks.filter((obj) => obj.id);

      setApiData({ ...apiData, data: { ...apiData.data, tasks: dbTasks } });
    }

    function handleDelete(element) {
      const dbTasks = apiData.data.tasks.filter((obj) => obj.id !== element.id);

      DBRequest({
        config,
        path: `work-packages/elements/list/tasks/${element.id}`,
        method: API_DELETE,
        onResponse: () => onDelete(element),
      });
      setApiData({ ...apiData, data: { ...apiData.data, tasks: dbTasks } });
    }

    return (
      <Box className="relative">
        <Box
          className="top-1 right-1 z-10 flex gap-5 items-center"
          sx={{ position: "absolute" }}
        >
          <Droppable
            droppableId={`trash ${element.id}`}
            key={`trash ${element.id}`}
          >
            {(provided, snapshot) => (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                {(isDragging || true) && (
                  <Box
                    sx={{
                      color: isDragging
                        ? snapshot.isDraggingOver
                          ? colors.error
                          : null
                        : "transparent",
                    }}
                  >
                    <DeleteIcon />
                  </Box>
                )}
              </div>
            )}
          </Droppable>

          <IconButton onClick={handleAddToDo}>
            <AddCircleIcon />
          </IconButton>
        </Box>
        <WPEContainer
          title={element.name}
          className="h-full w-full flex flex-col gap-2"
          sx={{ minHeight: "100px" }}
        >
          <Box className="flex items-center">
            <Tooltip title={t(hoverText)}>
              <Button onClick={handleToggleVisibility}>
                <Typography fontSize={12}>
                  {isCheckedVisible ? (
                    <VisibilityOffIcon fontSize="small" />
                  ) : (
                    <VisibilityIcon fontSize="small" />
                  )}
                  {` ${completedCount} ${t("completed Tasks")}`}
                </Typography>
              </Button>
            </Tooltip>
          </Box>
          {openNewToDo && (
            <NewWPEToDoElement
              onSubmit={handleNewToDo}
              onCancle={() => setOpenNewToDo(false)}
              wpeId={element.id}
            />
          )}

          {
            <>
              {false && (
                <Droppable
                  droppableId={`trash ${element.id}`}
                  key={`trash ${element.id}`}
                >
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      {isDragging && (
                        <Box
                          className="w-full rounded-lg flex justify-center items-center"
                          sx={{
                            background: `color-mix(in srgb, ${colors.error} 20%, transparent)`,
                            border: 2,
                            borderColor: colors.error,
                          }}
                        >
                          <DeleteIcon
                            sx={{ fontSize: "50px", color: colors.error }}
                          />
                          {provided.placeholder}
                        </Box>
                      )}
                    </div>
                  )}
                </Droppable>
              )}
              <Droppable
                droppableId={`tasks ${element.id}`}
                key={`tasks ${element.id}`}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      position: snapshot.isDraggingOver ? "relative" : "static", // Avoid shifting layout on drag over
                      transition: "height 0.2s ease-in-out", // Smooth transition for height
                      minHeight: "50px",
                    }}
                  >
                    {apiData?.data.tasks.map((task, index) => (
                      <WPEToDoElement
                        key={`${task.id}`}
                        draggingStyle={draggingStyle}
                        element={task}
                        onChange={handleChange}
                        onDelete={handleDelete}
                        className={
                          !isCheckedVisible
                            ? !task.status
                              ? ""
                              : "hidden"
                            : ""
                        }
                        index={index}
                        wpeId={element.id}
                        pid={pid}
                        isDragging={isDragging}
                      />
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </>
          }
        </WPEContainer>
      </Box>
    );
  }
);
