import { useTranslation } from 'react-i18next';
import ExoStepper, { FinalPage } from '../exo/ExoStepper';
import StepRecipient from '../steps/StepRecipient';
import findDifferences from '../exo/findDifferencesInDataCollection';
import { API_PATCH, API_POST, DBRequest, useConfig } from '../../api/api';
import * as Yup from 'yup';
import StepCustomStartUp from '../steps/StepCustomStartUp';
import { StepFiles } from '../steps/StepFiles';
import StepUser from '../steps/StepUser';
import StepDelivery from '../steps/StepDelivery';
import { StepRichtText } from '../steps/StepRichtText';
import { StepTemplate } from '../steps/StepTemplate';

export const NewLetter = ({
  startData,
  onClose,
  onUpdate = () => {},
  letters,
  setLetters,
  debug,
}) => {
  const { t } = useTranslation();
  const config = useConfig();

  const fields = [
    {
      label: t('Subject'),
      type: 'text',
      key: 'subject',
    },
    {
      label: t('Note'),
      type: 'description',
      key: 'note',
    },
  ];

  const validationSchema = Yup.object().shape({
    subject: Yup.string().required('Title is required'),
  });

  const steps = [
    {
      label: t('Recipient'),
      content: ({
        step,
        activeStep,
        setActiveStep,
        dataCollection,
        updateValidation,
        updateDataCollection,
      }) => (
        <StepRecipient
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t('Template'),
      content: ({
        dataCollection,
        updateValidation,
        updateDataCollection,
        activeStep,
        setSteps,
      }) => (
        <StepTemplate
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          templateType="letter"
          activeStep={activeStep}
          setSteps={setSteps}
        />
      ),
    },
    {
      label: t('Subject & Comment'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={fields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={validationSchema}
        />
      ),
    },
    {
      label: t('Content'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepRichtText
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t('Dokuments'),
      valid: true,
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepFiles
          type="MEDIA"
          dataKey="attachments"
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t('Contact Person'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepUser
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t('Delivery'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepDelivery
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
  ];

  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setIsLoading,
      handleError,
    }) => {
      setIsLoading(true);

      const diff = findDifferences(startData, dataCollection);
      DBRequest({
        config,
        path: startData && startData.id ? `letters/${startData.id}` : `letters`,
        method:
          startData && startData.id
            ? API_PATCH(diff)
            : API_POST(dataCollection),
        onResponse: handleRes,
        onLoading: setIsLoading,
        onError: handleError,
      });

      function handleRes(data) {
        handleResponse(data);
        onUpdate(data);
      }
    }, // your operation with the data
    content: (response) => (
      <FinalPage response={response} data={letters} setData={setLetters} />
    ), // component with final operations and the feedback
  };

  return (
    <ExoStepper
      draftType={'letter'}
      previewDocument={'letter'}
      debug={debug}
      label={startData && startData.id ? t('Edit Letter') : t('New Letter')}
      startDataCollection={startData}
      onClose={onClose}
      data={steps}
      finalStep={finalStep}
    />
  );
};
