import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../../global/theme/tokens";
import { useState } from "react";
import { API_PATCH, DBRequest, useConfig } from "../../../../api/api";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WPEToDoElementPopup from "./WPEToDoElementPopup";
import { ProjectUserStack } from "../ProjectUserStack";
import { Draggable } from "react-beautiful-dnd";
import { createPortal } from "react-dom";

export const WPEToDoElement = ({
  element,
  onChange,
  onDelete,
  className,
  index,
  wpeId,
  pid,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const config = useConfig();

  const [isProcessing, setIsProcessing] = useState(false);
  const [dataCollection, setDataCollection] = useState(element);
  const [openPopup, setOpenPopup] = useState(false);

  function handleToggleComplete() {
    if (isProcessing) return;
    element.status ? (dataCollection.status = 0) : (dataCollection.status = 1);
    DBRequest({
      config,
      path: `work-packages/elements/list/tasks/${element.id}`,
      method: API_PATCH(dataCollection),
      onResponse: handleResponse,
      onLoading: setIsProcessing,
    });
  }

  function handleStatusChange() {
    const newPriority = (element.priority + 1) % 4;
    dataCollection.priority = newPriority;
    DBRequest({
      config,
      path: `work-packages/elements/list/tasks/${element.id}`,
      method: API_PATCH(dataCollection),
      onResponse: handleResponse,
      //onLoading: setIsProcessing,
    });
  }

  function handleResponse(res) {
    onChange(res.data);
  }

  const priorityColorMap = {
    0: colors.grey,
    1: colors.success,
    2: colors.warning,
    3: colors.error,
  };

  return (
    <Draggable
      key={`${element.id}`}
      draggableId={`${element.id}`}
      index={index}
      //sx={{ position: "fixed" }}
    >
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          {!snapshot.isDragging && (
            <TaskContent
              element={element}
              onDelete={onDelete}
              className={className}
              priorityColorMap={priorityColorMap}
              onToggleComplete={handleToggleComplete}
              isProcessing={isProcessing}
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
              handleResponse={handleResponse}
              handleStatusChange={handleStatusChange}
              wpeId={wpeId}
              pid={pid}
            />
          )}

          {snapshot.isDragging &&
            createPortal(
              <div
                style={{
                  ...provided.draggableProps.style,
                  zIndex: 9999,
                  //boxSizing: "border-box",
                  //height: 46.5625,
                  //left: 264,
                  //opacity: null,
                  //pointerEvents: "none",
                  //position: "fixed",
                  //top: 382.40625,
                  //transform: providedTransform,
                  //transition:
                  //  "transform 0.34s cubic-bezier(.2,1,.1,1), opacity 0.34s cubic-bezier(.2,1,.1,1)",
                  //width: 1038,
                }}
              >
                <TaskContent
                  element={element}
                  onDelete={onDelete}
                  className={className}
                  priorityColorMap={priorityColorMap}
                  onToggleComplete={handleToggleComplete}
                  isProcessing={isProcessing}
                  openPopup={openPopup}
                  setOpenPopup={setOpenPopup}
                  handleResponse={handleResponse}
                  handleStatusChange={handleStatusChange}
                  wpeId={wpeId}
                  pid={pid}
                />
              </div>,
              document.body // Render outside the drag container to prevent layout issues
            )}
        </div>
      )}
    </Draggable>
  );
};

const TaskContent = ({
  element,
  onDelete,
  className,
  priorityColorMap,
  onToggleComplete,
  isProcessing,
  setOpenPopup,
  openPopup,
  handleResponse,
  handleStatusChange,
  wpeId,
  pid,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box className={`flex relative my-[3px] ${className}`}>
      <Box
        className="w-[10px] h-full absolute rounded-l-lg"
        onClick={handleStatusChange}
        sx={{ background: priorityColorMap[element.priority] }}
      />
      <Box
        className={`flex items-center gap-2 rounded-lg  px-3 py-1 w-full `}
        sx={{
          backgroundColor: colors.bgInput,
          border: "1px solid transparent",
        }}
      >
        <Box>
          <IconButton onClick={onToggleComplete}>
            {isProcessing ? (
              <span
                className="loader "
                style={{ width: "20px", height: "20px" }}
              />
            ) : element.status ? (
              <TaskAltIcon />
            ) : (
              <RadioButtonUncheckedIcon />
            )}
          </IconButton>
        </Box>
        <Box className="w-full h-full items-center flex">
          <Typography
            className="w-full"
            variant="h5"
            fontWeight={500}
            onClick={() => setOpenPopup(true)}
          >
            {element.name}
          </Typography>
          {element?.users?.length > 0 && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ProjectUserStack
                editmode={false}
                users={element.users}
                noLabel={true}
              />
            </Box>
          )}
        </Box>
        <WPEToDoElementPopup
          element={element}
          open={openPopup}
          onClose={() => setOpenPopup(false)}
          onResponse={handleResponse}
          onDelete={onDelete}
          wpeId={wpeId}
          pid={pid}
        />
      </Box>
    </Box>
  );
};
