import ExoForm from "../../../components/exo/ExoForm";
import { useTranslation } from "react-i18next";
import { API_POST, DBRequest, useConfig } from "../../../api/api";
import moment from "moment";
import { useState } from "react";
import * as Yup from "yup";
import { Box } from "@mui/material";

export const GenerateAccountingExport = ({ onResponse, onClose }) => {
  const { t } = useTranslation();
  const config = useConfig();
  const [isLoading, setIsLoading] = useState(false);

  function createElement(data) {
    const reqData = {
      year: parseInt(data.year),
      period: parseInt(data.period),
    };
    DBRequest({
      config,
      path: "exports/datev",
      method: API_POST(reqData),
      onResponse: onResponse,
      onLoading: setIsLoading,
    });
  }

  const fields = [
    {
      label: t("Year"),
      key: "year",
      type: "date",
      format: "YYYY",
      views: ["year"],
    },
    {
      label: t("Period"),
      key: "period",
      type: "accountingPeriod",
    },
  ];
  const defaultValues = {
    year: moment().format("YYYY"),
  };

  const validationSchema = Yup.object().shape({
    year: Yup.string().required(t("Is required")),
    period: Yup.string().required(t("Is required")),
  });

  return (
    <Box className="p-4">
      <ExoForm
        startDataCollection={defaultValues}
        className="flex flex-col gap-4"
        header={t("Generate Accounting")}
        fields={fields}
        onCancle={onClose}
        submitText={t("Generate")}
        onSubmit={createElement}
        isLoading={isLoading}
        noDifferences
        validationSchema={validationSchema}
      />
    </Box>
  );
};
