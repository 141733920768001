import { Box } from '@mui/material';
import StyledTextContainer from './StyledTextContainer';
import RenderHTML from './RenderHTML';
import TemplateVarSwitcher from '../templates/TemplateVarSwitcher';

const MailPreviewer = ({ template, onEdit, scale }) => {
  return (
    <StyledTextContainer
      styleJson={template.styleJson}
      presetScale={scale}
      autoOverflow
      onClick={onEdit}
    >
      <Box id={'headerP1-' + template.id}></Box>
      <Box id={'headerP2-' + template.id}></Box>
      <Box id={'footerP1-' + template.id}></Box>
      <Box id={'footerP2-' + template.id}></Box>
      <RenderHTML HTML={TemplateVarSwitcher(template.content, template.vars)} />
    </StyledTextContainer>
  );
};

export default MailPreviewer;
