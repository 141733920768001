import { useNavigate, useParams } from "react-router-dom";
import ExtendOffer from "./ExtendOffer";
import { Box } from "@mui/material";

export const ShowOffer = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      <ExtendOffer
        onDelete={() => {
          navigate("/offers");
        }}
        row={{
          id,
          onUpdate: () => {},
          onDelete: () => {},
        }}
      />
    </Box>
  );
};
