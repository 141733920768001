import { Box } from '@mui/material';
import ExoForm from '../../components/exo/ExoForm';
import * as Yup from 'yup';
import { API_POST, DBRequest, useConfig } from '../../api/api';
import Feedback from '../../components/special/Feedback';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const NewVacation = ({ onCreate, onCancle }) => {
  const { t } = useTranslation();

  const fields = [
    {
      label: t('Start'),
      type: 'date',
      key: 'startDate',
    },
    {
      label: t('End'),
      type: 'date',
      key: 'endDate',
    },
    {
      label: t('Is half start date'),
      type: 'checkbox',
      key: 'halfStartDate',
    },
    {
      label: t('Is half end date'),
      type: 'checkbox',
      key: 'halfEndDate',
    },
    {
      label: t('Comment'),
      type: 'text',
      key: 'comment',
      multiline: true,
      colspan: 2,
    },
  ];

  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required(t('Start Date is required')),
    endDate: Yup.string().required(t('End Date is required')),
  });
  const config = useConfig();

  function handleSubmit(values) {
    const data = {
      type: 'vacation',
      comment: values.comment,
      values: JSON.stringify(values),
    };

    DBRequest({
      config,
      path: 'employeerequests/vacation',
      method: API_POST(data),
      onResponse: handleCreation,
      onFeedback: handleAlertOpen,
    });
  }

  function handleCreation(data) {
    onCreate(data.data);
    onCancle();
  }

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: 'info',
    alertText: 'test',
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  function handleChange(data) {}

  return (
    <Box className="p-4">
      <ExoForm
        noDifferences
        fields={fields}
        onSubmit={handleSubmit}
        onCancle={onCancle}
        header={t('New Vacation')}
        validationSchema={validationSchema}
        className="grid grid-cols-2 gap-4"
        onChange={handleChange}
      />
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

export default NewVacation;
