import { Box, IconButton, Typography, useTheme } from '@mui/material';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { tokens } from '../../../global/theme/tokens';

export const CalendarNavigation = ({ label, onSubtract, onAdd }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="flex justify-between items-center px-2 py-1 rounded-lg z-20 mb-2"
      sx={{
        bgcolor: colors.grey[300],
      }}
    >
      <IconButton onClick={onSubtract}>
        <KeyboardArrowLeftIcon />
      </IconButton>

      <Typography fontWeight={500}>{label}</Typography>
      <IconButton onClick={onAdd}>
        <KeyboardArrowRightIcon />
      </IconButton>
    </Box>
  );
};
