import { Box, Skeleton, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { TimeElement } from '../../scenes/time-manager/user-entries/timeline/TimeElement';
import { tokens } from '../../global/theme/tokens';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { TimeInfo } from '../../scenes/time-manager/user-entries/timeline/TimeInfo';
import { CalendarNavigation } from './calendar/CalendarNavigation';
import TypeSkeleton from '../form/form-fields/TypeSkeleton';

export const ExoTimeOverviewCal = ({ times, onChange, activeTime }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { t } = useTranslation();

  const widthInPercent = 100 / times?.length;
  const [maxValue, setMaxValue] = useState(null);

  const [groupedTimes, setGroupedTimes] = useState(null);

  useEffect(() => {
    if (!times) return;
    calculateMaxValue();
    createTimesGroups();
  }, [times]);

  function calculateMaxValue() {
    var maxTargetTimeSeconds = 0;
    var maxWorkedTimeSeconds = 0;
    times.forEach((time) => {
      // get the biggest targetTime in times
      if (time.targetTimeSeconds > maxTargetTimeSeconds)
        maxTargetTimeSeconds = time.targetTimeSeconds;

      // get the max time in times
      const workedTimeSeconds = parseInt(time.totalTimeSeconds);
      if (workedTimeSeconds > maxWorkedTimeSeconds)
        maxWorkedTimeSeconds = workedTimeSeconds;
    });

    const biggestTimeSeconds =
      maxTargetTimeSeconds > maxWorkedTimeSeconds
        ? maxTargetTimeSeconds
        : maxWorkedTimeSeconds;

    setMaxValue(biggestTimeSeconds);
  }

  function createTimesGroups() {
    // Get the first and last date
    let firstDay = moment(times[0].date).startOf('isoWeek'); // Ensure we start on Monday
    let lastDay = moment(times[times.length - 1].date).endOf('isoWeek'); // Ensure we end on Sunday

    // Create a map for quick lookup
    const timesMap = new Map(
      times.map((item) => [moment(item.date).format('YYYY-MM-DD'), item])
    );

    // Generate a complete list of dates and insert objects (or placeholders if missing)
    let allDays = [];
    let current = firstDay.clone();

    while (current.isSameOrBefore(lastDay, 'day')) {
      const dateStr = current.format('YYYY-MM-DD');
      allDays.push(
        timesMap.get(dateStr) || { date: dateStr, placeholder: true }
      ); // Keep existing data or add blank
      current.add(1, 'day');
    }
    // Group into weeks
    let weeks = [];
    let currentWeek = [];

    allDays.forEach((day) => {
      currentWeek.push(day);
      if (moment(day.date).isoWeekday() === 7) {
        // Sunday
        weeks.push(currentWeek);
        currentWeek = [];
      }
    });
    setGroupedTimes(weeks);
  }
  const columns = [
    t('Monday'),
    t('Tuesday'),
    t('Wednesday'),
    t('Thursday'),
    t('Friday'),
    t('Saturday'),
    t('Sunday'),
  ];
  // popup info
  const [selectedTime, setSelectedTime] = useState(null);

  // change period
  function handleAdd() {
    setSelectedTime(null);
    const newStartDate = moment(times[0].date)
      .add(1, 'M')
      .startOf('M')
      .format('YYYY-MM-DD');
    const newEndDate = moment(times[0].date)
      .add(1, 'M')
      .endOf('M')
      .format('YYYY-MM-DD');

    onChange(newStartDate, newEndDate);
  }

  function handleSubtract() {
    setSelectedTime(null);
    const newStartDate = moment(times[0].date)
      .subtract(1, 'M')
      .startOf('M')
      .format('YYYY-MM-DD');
    const newEndDate = moment(times[0].date)
      .subtract(1, 'M')
      .endOf('M')
      .format('YYYY-MM-DD');

    onChange(newStartDate, newEndDate);
  }

  return (
    <Box className="w-full">
      <CalendarNavigation
        label={
          times
            ? `${t(moment(times[0].date).format('MMMM'))} ${moment(
                times[0].date
              ).format('YYYY')}`
            : ''
        }
        onAdd={handleAdd}
        onSubtract={handleSubtract}
      />
      <Box className="grid grid-cols-7 w-full gap-x-1">
        {columns.map((label, index) => (
          <ColumnLabel label={label} key={index} />
        ))}

        {times && groupedTimes
          ? groupedTimes?.map((week, index) => (
              <WeekGroup
                key={index}
                selectedTime={selectedTime}
                times={week}
                maxValue={maxValue}
                widthInPercent={widthInPercent}
                onSelect={setSelectedTime}
                activeTime={activeTime}
              />
            ))
          : Array(35)
              .fill(null)
              .map((_, index) => (
                <Skeleton
                  key={index}
                  sx={{
                    padding: 0,
                    margin: 0,
                    height: '150px',
                    transform: 'unset',
                    borderRadius: '10px',
                    marginBottom: '4px',
                    borderRadius: '3px',
                  }}
                />
              ))}
      </Box>
    </Box>
  );
};

const WeekGroup = ({
  times,
  maxValue,
  widthInPercent,
  onSelect,
  selectedTime,
  activeTime,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const isSelectedWeek = selectedTime
    ? 0 <= times.findIndex((obj) => obj.date === selectedTime.date)
    : false;
  return (
    <>
      {times?.map((time) => (
        <Box className="pt-1 flex justify-end" key={time.date}>
          <Typography
            sx={{
              width: '20px',
              borderRadius: '5px',
              bgcolor:
                moment().format('YYYY-MM-DD') === time.date
                  ? colors.selected
                  : 'transparent',
            }}
            textAlign="center"
            fontWeight={700}
            className={
              moment().format('YYYY-MM-DD') === time.date
                ? ''
                : time.placeholder
                ? 'opacity-10'
                : 'opacity-50'
            }
          >
            {moment(time.date).format('D')}
          </Typography>
        </Box>
      ))}
      {times?.map((time, index) =>
        time.placeholder ? (
          <Box
            key={time.date}
            sx={{
              backgroundImage: `linear-gradient(0deg, ${
                colors.selected + '90'
              }, ${colors.selected + '00'})`,
            }}
            className="rounded-md w-full h-full opacity-30"
          />
        ) : (
          <TimeElement
            key={time.date}
            time={time}
            format={'D'}
            height={150}
            maxValue={maxValue}
            widthInPercent={widthInPercent}
            onSelect={onSelect}
            hideDate
            hideLabel
            selected={selectedTime && selectedTime.date === time.date}
            activeTime={
              moment(time.date).format('DD-MM-YYYY') ===
                moment().format('DD-MM-YYYY') && activeTime
            }
          />
        )
      )}
      <Box className="col-span-7">
        <TimeInfo time={isSelectedWeek ? selectedTime : null} format={'dddd'} />
      </Box>
    </>
  );
};

const ColumnLabel = ({ label }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box className="px-1">
      <Typography textAlign="right" fontWeight={500}>
        {label.substring(0, 2)}
      </Typography>
    </Box>
  );
};
