import { Typography } from '@mui/material';
import CardContainer from '../../../components/cards/layout/CardContainer';
import CardGrid from '../../../components/cards/layout/CardGrid';
import PictureNameCard from '../../../components/cards/PictureNameCards/PictureNameCard';
import { RequestController } from './ExtendTimeCorrection';
import {
  requestTypesIcons,
  requestTypesStrings,
} from '../../../components/special/requestTypes';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../api/api';
import { useState } from 'react';
import Feedback from '../../../components/special/Feedback';
import DateLabel from '../../../components/label/DateLabel';

export const ExtendVacation = ({ request, onUpdate }) => {
  const { t } = useTranslation();
  const config = useConfig();

  // feedback
  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: 'info',
    alertText: 'test',
  });
  function handleAlertOpen(text, type) {
    setAlertState({ alertOpen: true, alertText: text, alertType: type });
  }

  return (
    <>
      <CardGrid>
        <CardContainer span={5} className="">
          <Typography
            variant="h3"
            className="flex items-center justify-center gap-2"
          >
            {requestTypesIcons[request.type]}
            {t(requestTypesStrings[request.type])}
          </Typography>
          <Typography variant="h6">
            <DateLabel date={request.values.startDate} />
            {' - '}
            <DateLabel date={request.values.endDate} />
          </Typography>
        </CardContainer>
        <PictureNameCard
          data={request.user}
          type="user"
          title={t('Requester')}
          pictureUrl={
            request.user.picture ? request.user.picture.temporaryUrl : ''
          }
          name={`${request.user.firstName} ${request.user.lastName}`}
          mail={request.user.email}
          phone={request.user.phone}
          position={request.user.position}
        />

        <CardContainer>
          <RequestController
            request={request}
            onUpdate={onUpdate}
            onFeedback={handleAlertOpen}
          />
        </CardContainer>
        {request.processedBy && (
          <PictureNameCard
            data={request.processedBy}
            type="user"
            title={t('Processor')}
            pictureUrl={
              request.processedBy.picture
                ? request.processedBy.picture.temporaryUrl
                : ''
            }
            name={`${request.processedBy.firstName} ${request.processedBy.lastName}`}
            mail={request.processedBy.email}
            phone={request.processedBy.phone}
            position={request.processedBy.position}
          />
        )}
      </CardGrid>
      <Feedback setState={setAlertState} state={alertState} />
    </>
  );
};
