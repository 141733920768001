import { useTheme, Box, Typography, Button } from '@mui/material';
import { tokens } from '../../../global/theme/tokens';
import ExoAvatar from '../../../components/exo/ExoAvatar';
import { ActiveTimeTracking } from './ActiveTimeTracking';
import { WorkTimeline } from './WorkTimeline';
import { NavLink } from 'react-router-dom';

const UserTimeOverview = ({ data, format, prefix }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const user = data.user;
  const name = `${user.lastName} ${user.firstName}`;
  return (
    <Box className="@container">
      <Box
        sx={{ borderBottom: `1px solid ${colors.selected}` }}
        className=" flex flex-col @2xl:flex-row"
      >
        <Box className="flex flex-col w-[300px]">
          <NavLink to={`/time-manager/${user.id}`}>
            <Button sx={{ padding: '0px' }}>
              <Box className="flex items-center gap-3 py-2 px-2 w-full">
                <ExoAvatar
                  picture={user.picture && user.picture.temporaryUrl}
                  name={name}
                  size="50px"
                />
                <Box className="w-full">
                  <Typography textAlign="left" fontWeight={600}>
                    {name}
                  </Typography>
                  <Typography textAlign="left" variant="h6">
                    {user.position || '-'}
                  </Typography>
                </Box>
              </Box>
            </Button>
          </NavLink>
          <ActiveTimeTracking activeTime={data.activeTimeTracking} />
        </Box>
        <WorkTimeline
          times={data.times}
          format={format}
          activeTime={data.activeTimeTracking}
          prefix={prefix}
        />
      </Box>
    </Box>
  );
};

export default UserTimeOverview;
