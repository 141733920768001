import { Box } from '@mui/material';
import { API_PATCH, API_POST, DBRequest, useConfig } from '../../api/api';

import ExoForm from '../exo/ExoForm';
import * as Yup from 'yup';
import findDifferences from '../exo/findDifferencesInDataCollection';
import Loader from '../special/Loader';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import StarIcon from '@mui/icons-material/Star';
import ReceiptIcon from '@mui/icons-material/Receipt';
import Feedback from '../special/Feedback';

const NewCompany = ({
  onClose = () => {},
  onCreate,
  onUpdate,
  startData,
  fullWidth,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const fields = [
    {
      label: `${t('Logo')} (${t('optional')})`,
      type: 'logo',
      key: 'logo',
    },
    {
      label: t('Company Name'),
      type: 'text',
      key: 'name',
    },
    {
      label: t('Company Name Short'),
      type: 'text',
      key: 'nameShort',
    },
    {
      label: `${t('Website')} (${t('optional')})`,
      type: 'text',
      key: 'url',
    },
    {
      label: `${t('E-Mails')} (${t('optional')})`,
      type: 'nameValueArray',
      key: 'emails',
      addLabel: t('Add E-Mail'),
      nameKey: 'emailType',
      valueKey: 'emailAddress',
      placeholder: {
        name: t('Label'),
        value: t('E-Mail'),
      },
      radioItems: [
        {
          key: 'isPrimary',
          Icon: StarIcon,
          label: 'Favorite',
        },
        {
          key: 'isInvoiceMail',
          Icon: ReceiptIcon,
          label: 'Invoice E-Mail',
        },
      ],
    },
    {
      label: `${t('Phonenumbers')} (${t('optional')})`,
      type: 'nameValueArray',
      key: 'telephoneNumbers',
      addLabel: t('Add phonenumber'),
      nameKey: 'phoneType',
      valueKey: 'phoneNumber',
      placeholder: {
        name: t('Label'),
        value: t('Phonenumber'),
      },
      radioItems: [
        {
          key: 'isPrimary',
          Icon: StarIcon,
          label: 'Favorite',
        },
      ],
    },
    {
      label: `${t('IBAN')} (${t('optional')})`,
      type: 'iban',
      key: 'iban',
    },
    {
      label: `${t('Industry')} (${t('optional')})`,
      type: 'industryId',
      key: 'industryId',
    },
    {
      label: t('Address'),
      type: 'address',
      key: 'address',
    },
    {
      label: `${t('Note')} (${t('optional')})`,
      type: 'richText',
      key: 'note',
    },
  ];

  const config = useConfig();

  function handleSubmit(data) {
    setIsLoading(true);

    const newData = {
      ...findDifferences(startData, data),
      emails: data.emails,
    };

    DBRequest({
      config,
      path: startData && startData.id ? `companies/${data.id}` : 'companies',
      method: startData && startData.id ? API_PATCH(newData) : API_POST(data),
      onResponse: handleResponse,
      onFeedback: handleFeedback,
    });
  }

  const [alertState, setAlertState] = useState({
    alertOpen: false,
    alertType: 'info',
    alertText: 'test',
  });

  function handleFeedback(text, type) {
    startData = setAlertState({
      alertOpen: true,
      alertText: text,
      alertType: type,
    });
    setIsLoading(false);
  }

  function handleResponse(data) {
    setIsLoading(false);
    onClose();
    if (startData && startData.id) {
      onUpdate(data);
    } else {
      onCreate(data);
    }
  }

  const phoneSchema = Yup.object().shape({
    phoneType: Yup.string().required('Is required'),
    phoneNumber: Yup.string().required('Is required'),
  });
  const emailSchema = Yup.object().shape({
    emailType: Yup.string().required('Is required'),
    emailAddress: Yup.string().required('Is required'),
  });

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(t('Is required')),
    nameShort: Yup.string().required(t('Is required')),
    address: Yup.object().shape({
      street: Yup.string().required(t('Is required')),
    }),
    telephoneNumbers: Yup.array().of(phoneSchema).nullable(),
    emails: Yup.array().of(emailSchema).nullable(),
  });

  return (
    <Box
      className="p-2 max-w-full h-full overflow-y-auto"
      sx={{ width: fullWidth ? '100%' : '500px' }}
    >
      <Loader active={isLoading} />
      <ExoForm
        noDifferences
        onSubmit={handleSubmit}
        fields={fields}
        validationSchema={validationSchema}
        startDataCollection={startData}
        onCancle={() => onClose()}
        draftType={'company'}
        titleFunction={(row) => row.name}
        header={
          startData && startData.id ? t('Edit Company') : t('New Company')
        }
      />
      <Feedback setState={setAlertState} state={alertState} />
    </Box>
  );
};

export default NewCompany;
