import {
  useTheme,
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import { useState, Fragment, useEffect } from 'react';
import { tokens } from '../../global/theme/tokens';
import {
  API_GET,
  API_PATCH,
  DBRequest,
  DBNoSetData,
  useConfig,
} from '../../api/api';
import TemplateEditor from '../../components/editor/TemplateEditor';
import TemplatePreview from '../../components/templates/TemplatePreview';
import Loader from '../../components/special/Loader';
import { useTranslation } from 'react-i18next';
import { TemplateGroup } from './templates/TemplateGroup';

const Templates = ({ onFeedback }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [serverData, setServerData] = useState(null);
  const config = useConfig();
  useEffect(() => {
    if (!config) return;
    DBRequest({
      config,
      path: 'templates',
      method: API_GET,
      onResponse: handleSetData,
      onLoading: setIsLoading,
    });
  }, [config]);

  const [selected, setSelected] = useState(null);
  function handleSelect(id) {
    const selectedTemplate = serverData.data.find(
      (template) => template.id === id
    );

    setSelected(selectedTemplate);
  }

  function parseKey(key) {
    const parts = key.split('_');

    // Check if the first part is a numeric prefix (e.g., "1", "2", "3")
    const prefixRegex = /^[1-3]$/;
    if (prefixRegex.test(parts[0])) {
      parts.shift(); // Remove the numeric prefix
    }

    // Extract the group and variant
    const lastPart = parts.pop(); // The last part is the variant
    const group = parts.filter((part) => part !== 'mail').join('_'); // Exclude "mail" from the group

    return {
      group,
      variant: lastPart,
    };
  }

  function handleSetData(response) {
    setServerData(response);
    // sort and split data with classification
    var groupedData = {};

    const specialKeys = [
      'personnel_questionnaire',
      'privacy_policy',
      'terms_and_conditions',
      'employee_contract',
      'timesheet',
    ];

    response.data.forEach((template) => {
      const format = template.templateFormat;

      if (specialKeys.includes(template.templateType)) {
        groupedData[template.templateType] = {};
        return (groupedData[template.templateType][format] = [template]);
      }

      const { group, variant } = parseKey(template.templateType);

      if (!groupedData[group]) groupedData[group] = {};

      if (!groupedData[group][format]) groupedData[group][format] = [];

      groupedData[group][format].push({ ...template, variant });
    });

    setData({ ...groupedData, keys: Object.keys(groupedData) });
  }
  function handleCloseEditor() {
    setSelected(null);
  }
  function handleSaveEditor(updatedTemplate) {
    const newTemplates = serverData.data.map((template) => {
      if (template.id === updatedTemplate.id) {
        return updatedTemplate;
      }
      return template;
    });
    handleSetData({ ...serverData, data: newTemplates });
    setSelected(null);
  }

  const templateTypeMap = {
    payment_reminder: {
      label: t('Payment Reminder'),
    },
    contract: {
      label: t('Contract'),
    },
    delivery_note: {
      label: t('Delivery Note'),
    },
    employee_contract: {
      label: t('Employee'),
    },
    invoice: {
      label: t('Invoice'),
    },
    letter: {
      label: t('Letter'),
    },
    offer: {
      label: t('Offer'),
    },
    order: {
      label: t('Order'),
    },
    personnel_questionnaire: {
      label: t('Employee Info'),
    },
    privacy_policy: {
      label: t('Privacy Policy'),
    },
    terms_and_conditions: {
      label: t('Terms and Conditions'),
    },
    default: {
      label: t('Default'),
    },
    timesheet: {
      label: t('Timesheet'),
    },
  };

  return (
    <Box className="w-full h-full flex flex-col gap-2 overflow-y-auto pt-2 ">
      {isLoading ? (
        <Loader active={true} message="Loading Templates ..." />
      ) : (
        data?.keys.map((key) => (
          <TemplateGroup
            key={key}
            group={data[key]}
            onSelect={handleSelect}
            label={templateTypeMap[key]?.label}
          />
        ))
      )}
      <TemplateEditor
        onFeedback={onFeedback}
        open={Boolean(selected)}
        templateData={selected || {}}
        onClose={handleCloseEditor}
        onSave={handleSaveEditor}
      />
    </Box>
  );
};

export default Templates;
