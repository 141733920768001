import { Box, useTheme, Typography } from '@mui/material';
import { TimeElement } from './timeline/TimeElement';
import { useEffect, useState } from 'react';
import { tokens } from '../../../global/theme/tokens';
import moment from 'moment';
import { TimeInfo } from './timeline/TimeInfo';

export const WorkTimeline = ({
  times,
  format,
  height = 150,
  activeTime,
  prefix,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const widthInPercent = 100 / times.length;

  const [maxValue, setMaxValue] = useState(null);

  useEffect(() => {
    calculateMaxValue();
  }, [times]);

  function calculateMaxValue() {
    var maxTargetTimeSeconds = 0;
    var maxWorkedTimeSeconds = 0;
    times.forEach((time) => {
      // get the biggest targetTime in times
      if (time.targetTimeSeconds > maxTargetTimeSeconds)
        maxTargetTimeSeconds = time.targetTimeSeconds;

      // get the max time in times
      const workedTimeSeconds = parseInt(time.totalTimeSeconds);
      if (workedTimeSeconds > maxWorkedTimeSeconds)
        maxWorkedTimeSeconds = workedTimeSeconds;
    });

    const biggestTimeSeconds =
      maxTargetTimeSeconds > maxWorkedTimeSeconds
        ? maxTargetTimeSeconds
        : maxWorkedTimeSeconds;

    setMaxValue(biggestTimeSeconds);
  }

  // popup info
  const [selectedTime, setSelectedTime] = useState(null);

  return (
    <Box className="@container w-full">
      <Box
        className={`grid w-full relative pt-1 px-1 overflow-x-auto @lg:overflow-hidden`}
        sx={{
          gridTemplateColumns: `repeat(${times.length}, minmax(0, 1fr))`,
        }}
      >
        {times.map((time) => (
          <TimeElement
            key={time.date}
            onSelect={setSelectedTime}
            time={time}
            widthInPercent={widthInPercent}
            format={format}
            maxValue={maxValue}
            height={height}
            prefix={prefix}
            activeTime={
              moment(time.date).format('DD-MM-YYYY') ===
                moment().format('DD-MM-YYYY') && activeTime
            }
            onMaxValueChange={setMaxValue}
            selected={selectedTime?.date === time.date}
          />
        ))}
      </Box>
      <TimeInfo time={selectedTime} format={format} prefix={prefix} />
    </Box>
  );
};
