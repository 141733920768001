import UserTimeDetails from '../../user-time-details';

export const EmployeeTimes = ({ employeeInfo, noPicture }) => {
  return (
    <UserTimeDetails
      noBackground
      id={employeeInfo.id}
      noPicture={noPicture}
      noLegend
    />
  );
};
