import { useTheme } from '@mui/material';
import { tokens } from '../../global/theme/tokens';
import { API_PATCH, API_POST, DBRequest, useConfig } from '../../api/api';
import ExoStepper, { FinalPage } from '../exo/ExoStepper';
import StepRecipient from '../steps/StepRecipient';
import StepPositions from '../steps/StepPositions';
import StepCustomStartUp from '../steps/StepCustomStartUp';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import moment from 'moment';
import StepUser from '../steps/StepUser';
import { StepFiles } from '../steps/StepFiles';
import StepDelivery from '../steps/StepDelivery';
import { StepTemplate } from '../steps/StepTemplate';

const NewCustomOrder = ({
  orders,
  setOrders,
  onClose,
  startData,
  onUpdate = () => {},
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const fields = [
    {
      label: t('Title'),
      type: 'text',
      key: 'title',
    },
    {
      label: t('Start Date'),
      type: 'date',
      key: 'startDate',
    },
    {
      label: t('Delivery Date'),
      type: 'date',
      key: 'deliveryDate',
    },

    {
      label: t('Note'),
      type: 'description',
      key: 'note',
      multiline: true,
    },
    {
      type: 'hidden',
      key: 'sourceType',
      value: 'custom',
    },
  ];

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t('Title is required')),
    deliveryDate: Yup.string().required(t('Delivery Date is required')),
  });

  const steps = [
    {
      label: t('Recipient'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepRecipient
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t('Template'),
      content: ({
        dataCollection,
        updateValidation,
        updateDataCollection,
        setSteps,
        activeStep,
      }) => (
        <StepTemplate
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          templateType="order"
          setSteps={setSteps}
          activeStep={activeStep}
        />
      ),
    },
    {
      label: t('Date & Comment'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepCustomStartUp
          fields={fields}
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          validationSchema={validationSchema}
        />
      ),
    },
    {
      label: t('Dokuments'),
      valid: true,
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepFiles
          type="ORDER_ATTACHMENT"
          dataKey="attachments"
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          filter={
            dataCollection.id
              ? `orderAttachments[has]=${dataCollection.id}`
              : 'orderAttachments[doesnothave]=0&orderImages[doesnothave]=0'
          }
        />
      ),
    },
    {
      label: t('Positions'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepPositions
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          imageType={`ORDER_ATTACHMENT`}
          imageApiFilter={
            startData && startData.id
              ? `orderImages[has]=${startData.id}`
              : `orderImages[doesnothave]=0`
          }
        />
      ),
    },
    {
      label: t('Contact Person'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepUser
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
        />
      ),
    },
    {
      label: t('Delivery'),
      content: ({ dataCollection, updateValidation, updateDataCollection }) => (
        <StepDelivery
          updateDataCollection={updateDataCollection}
          dataCollection={dataCollection}
          updateValidation={updateValidation}
          allowMailContent={true}
        />
      ),
    },
  ];
  const config = useConfig();
  const finalStep = {
    operation: ({
      dataCollection,
      handleResponse,
      setSuccess,
      setIsLoading,
      handleError,
    }) => {
      setIsLoading(true);

      const isUpdate = Boolean(dataCollection.id);

      DBRequest({
        config,
        path: isUpdate ? `orders/${dataCollection.id}` : 'orders',
        method: isUpdate ? API_PATCH(dataCollection) : API_POST(dataCollection),
        onResponse: handleRes,
        onLoading: setIsLoading,
        onError: handleError,
      });

      function handleRes(data) {
        handleResponse(data);
        onUpdate(data);
      }
    }, // your operation with the data
    content: (response) => (
      <FinalPage response={response} data={orders} setData={setOrders} />
    ), // component with final operations and the feedback
  };

  const [defaultData, setDefaultData] = useState({});

  useEffect(() => {
    if (!config) return;
    const today = moment().format();
    const newDefaultData = {
      startDate: today,
      deliveryDate: today,
      sourceType: 'custom',
    };
    setDefaultData(newDefaultData);
  }, [config]);

  return (
    <ExoStepper
      draftType={'custom-order'}
      label={startData && startData.id ? t('Edit Order') : t('New Order')}
      message={t('Create Order')}
      startDataCollection={startData || defaultData}
      onClose={onClose}
      data={steps}
      finalStep={finalStep}
      previewDocument={'order'}
    />
  );
};

export default NewCustomOrder;
