import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import ExtendReceipt from "./ExtendReceipt";
import { useRef, useState } from "react";
import { updateOrCreate } from "../../../components/special/updateOrCreate";
import NewReceipt from "../../../components/new/NewReceipt";
import ExoDialog from "../../../components/exo/ExoDialog";
export const ShowReceipt = () => {
  const { id } = useParams();
  const extendRef = useRef(null);
  //const { apiData, setApiData } = useFetch("receipts");

  const navigate = useNavigate();

  const [newReceipt, setNewReceipt] = useState(false);

  // actions
  const [receiptToEdit, setReceiptToEdit] = useState(null);
  function handleClickEdit(data) {
    setReceiptToEdit({
      ...data,
      receipt: {
        amount: data.amount,
        position: data.position,
        taxAssignment: data.taxAssignment,
        taxRate: data.taxRate,
        costCenter: data.costCenter,
        note: data.note,
      },
    });
    setNewReceipt(true);
  }

  function handleCreateReceipt(newReceipt, status) {
    extendRef.current.setData(newReceipt);
    setNewReceipt(false);
    if (status === 201) {
      navigate("/expenses");
    }
  }

  function handleDelete() {
    navigate("/expenses");
  }

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      <ExtendReceipt
        ref={extendRef}
        row={{
          id,
          onUpdate: () => {},
          onDelete: () => {},
        }}
        //onUpdate={onUpdate}
        onClickEdit={handleClickEdit}
        onDelete={handleDelete}
      />
      <ExoDialog open={newReceipt}>
        <NewReceipt
          onCreate={handleCreateReceipt}
          onClose={() => setNewReceipt(false)}
          receipt={receiptToEdit}
          open={newReceipt}
        />
      </ExoDialog>
    </Box>
  );
};
