import { Box, useTheme } from "@mui/material";
import { tokens } from "../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import CardGrid from "../../../components/cards/layout/CardGrid";
import { API_DELETE, API_POST, DBRequest, useConfig } from "../../../api/api";
import FilePreviewCard from "../../../components/cards/FilePreviewCard";
import SpeedDialMenu from "../../../components/menu/SpeedDialMenu";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import RestorePageIcon from "@mui/icons-material/RestorePage";

export const ExpandTimeSheet = ({
  row,
  onCreate,
  onDelete,
  preventActions = false,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  function handleDelete() {
    DBRequest({
      config,
      path: `time-sheets/${row.id}`,
      method: API_DELETE,
      onResponse: () => onDelete(row),
    });
  }

  function regenerateTimeSheet() {
    DBRequest({
      config,
      path: `time-sheets/${row.id}/regenerate`,
      method: API_POST({}),
      onResponse: handleRegeneratedElement,
    });
  }

  async function handleRegeneratedElement(res) {
    onCreate(res, row);
  }

  const actions = [
    {
      icon: <RestorePageIcon />,
      name: t("Regenerate"),
      onClick: regenerateTimeSheet,
    },
    { icon: <DeleteOutlineIcon />, name: t("Delete"), onClick: handleDelete },
  ];

  return (
    <Box className="flex flex-col gap-4 relative overflow-y-auto">
      <CardGrid>
        {!preventActions && <SpeedDialMenu actions={actions} />}
        <FilePreviewCard colSpan={2} file={row.file} />
      </CardGrid>
    </Box>
  );
};
