import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import { ExpandPayroll } from "./ExpandPayroll";
import useFetch from "../../../api/useFetch";
import { useState } from "react";
import { UploadPayroll } from "./UploadPayroll";
import ExoDialog from "../../../components/exo/ExoDialog";
import { updateOrCreate } from "../../../components/special/updateOrCreate";
export const ShowPayroll = () => {
  const { id } = useParams();

  const { apiData, setApiData } = useFetch(`payrolls/${id}`);

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);

  function handleNewElement(newElement) {
    setOpen(false);
    setApiData(updateOrCreate(apiData, newElement.data.data));
  }

  const [edit, setEdit] = useState(null);

  function handleEdit(data) {
    setEdit(data);
    setOpen(true);
  }

  function handleClose() {
    setEdit(null);
    setOpen(false);
  }

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      {apiData && (
        <ExpandPayroll
          row={apiData?.data}
          onEdit={handleEdit}
          onDelete={() => navigate("/expenses")}
        />
      )}
      <ExoDialog open={open} onClose={handleClose}>
        <UploadPayroll
          payroll={edit}
          onResponse={handleNewElement}
          onClose={handleClose}
        />
      </ExoDialog>
    </Box>
  );
};
