import { useTheme, Box } from '@mui/system';
import { tokens } from '../../global/theme/tokens';
import { useEffect, useRef, useState } from 'react';
import { Slider } from '@mui/material';
const StyledTextContainer = ({
  className,
  sx,
  children,
  styleJson,
  aspectRatio,
  scaleable = false,
  presetScale,
  autoOverflow = true,
  fullWidth = false,
  backgroundColor = 'white',
  onClick = () => {},
  noStyles,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const defaultStyle = {
    page: {
      size: 794,
    },
    papermargin: {
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
    },
    font: {
      url: 'https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap',
      fontFamily: '"Montserrat", sans-serif',
      fontWeight: '400',
    },
    p: {
      fontSize: '12px',
      margin: '0px',
      padding: '0px',
      color: colors.grey[900],
      lineHeight: 'normal',
    },
    h1: {
      fontSize: '28px',
      margin: '0px',
      padding: '0px',
      color: colors.grey[900],
      lineHeight: 'normal',
    },
    h2: {
      fontSize: '24px',
      margin: '0px',
      padding: '0px',
      color: colors.grey[900],
      lineHeight: 'normal',
    },
    h3: {
      fontSize: '20px',
      margin: '0px',
      padding: '0px',
      color: colors.grey[900],
      lineHeight: 'normal',
    },
    h4: {
      fontSize: '16px',
      margin: '0px',
      padding: '0px',
      color: colors.grey[900],
      lineHeight: 'normal',
    },
    h5: {
      fontSize: '12px',
      margin: '0px',
      padding: '0px',
      color: colors.grey[900],
      lineHeight: 'normal',
    },
    h6: {
      fontSize: '10px',
      margin: '0px',
      padding: '0px',
      color: '#494a50',
      lineHeight: 'normal',
    },
    ul: {
      fontSize: '12px',
      margin: '0px 0px 0px 20px',
      padding: '0px',
      color: colors.grey[900],
      lineHeight: 'normal',
      listStyle: 'disc',
    },
    ol: {
      fontSize: '12px',
      margin: '0px 0px 0px 20px',
      padding: '0px',
      color: colors.grey[900],
      lineHeight: 'normal',
      listStyle: 'decimal',
    },
    th: {
      fontSize: '14px',
      fontWeight: '100',
      color: colors.grey[900],
      backgroundColor: '#e7e7e7',
      padding: '5px',
      lineHeight: 'normal',
    },
    td: {
      fontSize: '12px',
      fontWeight: '100',
      color: colors.grey[900],
      backgroundColor: '',
      padding: '5px',
      lineHeight: 'normal',
    },
  };

  const [style, setStyle] = useState(defaultStyle);

  useEffect(() => {
    // decode styleJson
    try {
      setStyle(JSON.parse(styleJson));
    } catch (e) {
      setStyle(defaultStyle);
      console.warn('Styles not valid');
    }
  }, [styleJson]);

  // scale
  const [scale, setScale] = useState(presetScale || 1);

  useEffect(() => {
    setScale(presetScale || 1);
  }, [presetScale]);

  return (
    <Box
      className={'h-full w-full flex flex-col overflow-auto ' + className}
      sx={sx}
    >
      {scaleable && (
        <Box
          className="w-full px-5 sticky top-0 z-10 "
          sx={{
            borderBottom: '2px solid ' + colors.primary[400],
          }}
        >
          <Slider
            className=" z-30"
            aria-label="Scale"
            defaultValue={presetScale}
            getAriaValueText={setScale}
            step={0.05}
            marks
            min={0.4}
            max={1.8}
          />
        </Box>
      )}
      <Box className="overflow-auto w-full h-full">
        <Box
          onClick={onClick}
          className="rounded-lg h-full"
          sx={{
            transform: 'scale(' + scale + ')',
            transformOrigin: 'top left',
            backgroundColor: backgroundColor,
            width: fullWidth ? '100%' : style.page.size + 'px',
            height: aspectRatio ? style.page.size / aspectRatio + 'px' : 'auto',
            li: {
              marginLeft: '20px!important',
            },
            ol: {
              listStyle: 'decimal',
            },
            'p, h1, h2, h3, h4, h5, h6, a': {
              textAlign: 'left',
            },
            maxHeight: aspectRatio
              ? style.page.size / aspectRatio + 'px'
              : 'auto',
            padding:
              style.papermargin.top +
              'px ' +
              style.papermargin.right +
              'px ' +
              style.papermargin.bottom +
              'px ' +
              style.papermargin.left +
              'px ',
          }}
        >
          <Box
            className="h-full w-full"
            sx={{
              position: 'relative',
              maxWidth: '100%',
              minWidth: '100%',
              width: '100%',
              overflow: autoOverflow ? 'hidden' : 'auto',
              aspectRatio: aspectRatio ? aspectRatio : 'auto',
              transformOrigin: 'top',
              color: style.p.color,

              '& *': {
                fontFamily: style.font.fontFamily,
                margin: '0',
                padding: '0',
              },
              '& h1, h1 span': {
                color: style.h1.color,
                fontSize: style.h1.fontSize,
                padding: style.h1.padding,
                margin: style.h1.margin,
                lineHeight: style.h1.lineHeight,
                textAlign: style.h1.textAlign,
              },
              '& h2, h2 span': {
                color: style.h2.color,
                fontSize: style.h2.fontSize,
                padding: style.h2.padding,
                margin: style.h2.margin,
                lineHeight: style.h2.lineHeight,
                textAlign: style.h2.textAlign,
              },
              '& h3, h3 span': {
                color: style.h3.color,
                fontSize: style.h3.fontSize,
                padding: style.h3.padding,
                margin: style.h3.margin,
                lineHeight: style.h3.lineHeight,
                textAlign: style.h3.textAlign,
              },
              '& h4, h4 span': {
                color: style.h4.color,
                fontSize: style.h4.fontSize,
                padding: style.h4.padding,
                margin: style.h4.margin,
                lineHeight: style.h4.lineHeight,
                textAlign: style.h4.textAlign,
              },
              '& h5, h5 span': {
                color: style.h5.color,
                fontSize: style.h5.fontSize,
                padding: style.h5.padding,
                margin: style.h5.margin,
                lineHeight: style.h5.lineHeight,
                textAlign: style.h5.textAlign,
              },
              '& h6, h6 span': {
                color: style.h6.color,
                fontSize: style.h6.fontSize,
                padding: style.h6.padding,
                margin: style.h6.margin,
                lineHeight: style.h6.lineHeight,
                textAlign: style.h6.textAlign,
              },
              '& p, & div': {
                color: style.p.color,
                fontSize: style.p.fontSize,
                padding: style.p.padding,
                margin: style.p.margin,
                textAlign: style.p.textAlign,
                lineHeight: style.p.lineHeight,
              },
              '& ul': {
                color: style.ul.color,
                fontSize: style.ul.fontSize,
                padding: style.ul.padding,
                lineHeight: style.ul.lineHeight,
                listStyle: style.ul.listStyle,
                textAlign: style.ul.textAlign,
                '& li': {
                  margin: style.ul.margin,
                  textAlign: style.ul.textAlign,
                },
              },
              '& ol': {
                color: style.ol.color,
                fontSize: style.ol.fontSize,
                padding: style.ol.padding,
                lineHeight: style.ol.lineHeight,
                listStyle: style.ol.listStyle,
                textAlign: style.ol.textAlign,
                '& li': {
                  margin: style.ol.margin,
                  textAlign: style.ol.textAlign,
                },
              },

              '& th': {
                color: style.th.color,
                backgroundColor: style.th.backgroundColor,
                fontSize: style.th.fontSize,
                fontWeight: style.th.fontWeight,
                padding: style.th.padding,
                lineHeight: style.th.lineHeight,
                textAlign: style.th.textAlign,
              },
              '& td': {
                backgroundColor: style.td.backgroundColor,
                fontSize: style.td.fontSize,
                fontWeight: style.td.fontWeight,
                color: style.td.color,
                padding: style.td.padding,
                lineHeight: style.td.lineHeight,
                textAlign: style.td.textAlign,
              },
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default StyledTextContainer;
