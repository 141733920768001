import { useEffect, useState } from "react";
import ExoPositions from "../exo/ExoPositions";
import { Alert, Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../global/theme/tokens";
import MoneyLabel from "../label/MoneyLabel";
import { t } from "i18next";
import { useDebounce } from "use-debounce";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";

const StepPositionsOrder = ({
  updateDataCollection,
  dataCollection,
  updateValidation,
  imageType,
  imageApiFilter,
  collapseModules,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  function handlePositionChange(positions) {
    updateDataCollection({ positions: positions });
  }

  const [sum, setSum] = useState(0);

  const [taxRateMissmatch, setTaxRateMissmatch] = useState(false);

  const [debouncedPositions] = useDebounce(dataCollection, 700);

  useEffect(() => {
    let taxRateMissmatch = false;
    if (!dataCollection.positions) return;
    var newSum = 0;
    dataCollection.positions.forEach((element) => {
      if (element.taxRate !== dataCollection.templateMetaData.taxRate) {
        taxRateMissmatch = true;
      }
      if (element.orderPositionId || element.isOrderPosition)
        newSum += element.amount * element.price;
    });

    setTaxRateMissmatch(taxRateMissmatch);
    setSum(newSum);
  }, [debouncedPositions]);

  // validation
  useEffect(() => {
    var pos = dataCollection.positions;
    if (pos == undefined) pos = [];
    var valPositions = true;

    for (let index = 0; index < pos.length; index++) {
      if (
        !pos[index].isGroup &&
        (!pos[index].name ||
          isNaN(pos[index].price) ||
          pos[index].price == 0 ||
          pos[index].amount == 0 ||
          isNaN(pos[index].amount))
      ) {
        valPositions = false;
      }
    }
    updateValidation(valPositions);
  }, [dataCollection]);

  return (
    <Box className="h-full flex flex-col gap-2">
      <OrderPreview order={dataCollection.order} sum={sum} />
      <ExoPositions
        imageType={imageType}
        imageApiFilter={imageApiFilter}
        onPositionChange={handlePositionChange}
        startPositions={dataCollection.positions}
        collapseModules={collapseModules}
        defaultTaxRate={
          dataCollection.templateMetaData &&
          dataCollection.templateMetaData.taxRate
        }
        openAmount={dataCollection.order.openVolume}
      />
      {taxRateMissmatch && (
        <Box className="w-full flex justify-center">
          <Alert severity="warning" sx={{ flexDirection: "row!important" }}>
            {t(
              "At least one invoice-position contains a tax rate, which does not match the template"
            )}
          </Alert>
        </Box>
      )}
    </Box>
  );
};

const OrderPreview = ({ order, sum }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  return (
    <Box
      className="flex gap-2 px-3 py-2 rounded-lg justify-between"
      sx={{
        bgcolor: colors.bgInput,
      }}
    >
      <Box className="flex flex-col items-start">
        <Typography
          variant="h4"
          className="monospace flex gap-2"
          textAlign="center"
          sx={{ fontWeight: 500 }}
        >
          <MoneyLabel money={order.openVolume} />
        </Typography>
        <Typography variant="h6" textAlign="center">
          {t("Open before invoice")}
        </Typography>
      </Box>
      <Box className="flex flex-col items-end">
        <Typography
          variant="h4"
          className="monospace flex gap-2"
          textAlign="right"
          sx={{
            fontWeight: 500,
            color: order.openVolume - sum >= 0 ? colors.success : colors.error,
          }}
        >
          {order.openVolume - sum === 0 && <PriceCheckIcon />}

          <MoneyLabel money={order.openVolume - sum} />
        </Typography>
        <Typography variant="h6" textAlign="right">
          {t("Open after invoice")}
        </Typography>
      </Box>
    </Box>
  );
};

export default StepPositionsOrder;
