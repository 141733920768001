import { Typography } from '@mui/material';
import CardContainer from '../../../components/cards/layout/CardContainer';
import CardGrid from '../../../components/cards/layout/CardGrid';
import PictureNameCard from '../../../components/cards/PictureNameCards/PictureNameCard';
import {
  requestTypesIcons,
  requestTypesStrings,
} from '../../../components/special/requestTypes';
import { useTranslation } from 'react-i18next';
import { useConfig } from '../../../api/api';
import DateLabel from '../../../components/label/DateLabel';

export const ExtendVacation = ({ request, onUpdate }) => {
  const { t } = useTranslation();
  const config = useConfig();

  return (
    <CardGrid>
      <CardContainer span={3} className="">
        <Typography
          variant="h3"
          className="flex items-center justify-center gap-2"
        >
          {requestTypesIcons[request.type]}
          {t(requestTypesStrings[request.type])}
        </Typography>
        <Typography variant="h6">
          <DateLabel date={request.values.startDate} />
          {' - '}
          <DateLabel date={request.values.endDate} />
        </Typography>
      </CardContainer>
      {request.processedBy && (
        <PictureNameCard
          data={request.processedBy}
          type="user"
          title={t('Processor')}
          pictureUrl={
            request.processedBy.picture
              ? request.processedBy.picture.temporaryUrl
              : ''
          }
          name={`${request.processedBy.firstName} ${request.processedBy.lastName}`}
          mail={request.processedBy.email}
          phone={request.processedBy.phone}
          position={request.processedBy.position}
        />
      )}
    </CardGrid>
  );
};
