import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import ExoTable from "../../../components/exo/ExoTable";
import useFetch from "../../../api/useFetch";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import MoneyLabel from "../../../components/label/MoneyLabel";
import DateLabel from "../../../components/label/DateLabel";
import CardGrid from "../../../components/cards/layout/CardGrid";
import FilePreviewCard from "../../../components/cards/FilePreviewCard";

const EmployeePayrolls = ({ employeeInfo, own }) => {
  const { t } = useTranslation();
  const { apiData, isLoading } = useFetch(
    own ? `payrolls/own` : `payrolls?user[eq]=${employeeInfo.id}`
  );

  const Columns = [
    {
      header: <PriceCheckIcon />, // header of column
      key: "transaction", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "33px", // width of column
      cell: ({ row }) => <Box>{row.transaction && <PriceCheckIcon />}</Box>,
    },
    {
      header: <RequestPageIcon />, // header of column
      key: "file", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column
      width: "33px", // width of column
      hiddenMobile: true,
      cell: ({ row }) => <Box>{row.file && <RequestPageIcon />}</Box>,
    },
    {
      header: t("Monat"), // header of column
      key: "endDate", // key of column in row-object
      thAlign: "left", // header text alignment
      tdAlign: "left", // cell text alignment
      sortable: true, // enable sort function for column

      cell: ({ row }) => (
        <Box className="monospace">
          <DateLabel format="MM/YYYY" date={row.endDate} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },

    {
      header: t("Salary"), // header of column
      key: "startDate", // key of column in row-object
      thAlign: "right", // header text alignment
      tdAlign: "right", // cell text alignment
      sortable: true, // enable sort function for column
      width: "230px", // width of column
      cell: ({ row }) => (
        <Box className="monospace">
          <MoneyLabel money={row.amount} />
        </Box>
      ), // custom xml for cells in the column. you get the row as object
    },
  ];

  return (
    <ExoTable
      className=" w-full"
      extendElement={({ row }) => <ExpandPayroll row={row} />}
      data={apiData}
      isLoading={isLoading}
      columns={Columns}
      noBackground
    />
  );
};

export const ExpandPayroll = ({ row }) => {
  return (
    <Box className="flex flex-col gap-4 relative overflow-y-auto">
      <CardGrid>
        <FilePreviewCard file={row.file} colSpan={99} />
      </CardGrid>
    </Box>
  );
};

export default EmployeePayrolls;
