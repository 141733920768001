import { Box, Select, Typography } from "@mui/material";
import { t } from "i18next";
import { useState } from "react";
import TypeCustomSelect from "../../form/form-fields/TypeCustomSelect";

const FilterItemDropdown = ({ item, onChange, checked }) => {
  const [dataCollection, setDataCollection] = useState({});

  function handleChange(key, value) {
    const newDataCollection = { ...dataCollection, [key]: value };
    setDataCollection(newDataCollection);
    onChange({
      ...item,
      value: `${item.baseValue}${value}`,
      active: true,
      dataCollection: newDataCollection,
    });
  }
  return (
    <TypeCustomSelect
      dataCollection={dataCollection}
      startDataCollection={item.dataCollection || {}}
      errors={{}}
      updateValidationOnChange={handleChange}
      field={{
        label: item.label,
        key: "test",
        options: item.options,
      }}
    />
  );
};

export default FilterItemDropdown;
