import { useNavigate, useParams } from "react-router-dom";
import ExtendProduct from "./ExtendProduct";
import { Box } from "@mui/material";

export const ShowProduct = () => {
  const { id } = useParams();

  const navigate = useNavigate();

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      <ExtendProduct
        onDelete={() => navigate("/products")}
        row={{ id, onUpdate: () => {}, onDelete: () => {} }}
      />
      ;
    </Box>
  );
};
