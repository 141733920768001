import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const TimeLabel = ({ time, format = 'HH:mm:ss' }) => {
  const { t } = useTranslation();
  const [timeLabel, setTimeLabel] = useState('');

  useEffect(() => {
    setTimeLabel(moment(time).format(format));
  }, [time]);
  return <>{timeLabel === 'Invalid date' ? t('Invalide') : timeLabel}</>;
};
