import { Box, useTheme } from '@mui/material';
import { tokens } from '../../global/theme/tokens';

export const FormFieldsHighlightContainer = ({
  children,
  error,
  field,
  hasValidation,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  return (
    <Box
      className="w-full flex flex-col cols rounded-xl"
      sx={{
        gridColumn: `span ${field.colspan} / span ${field.colspan}`,
        borderLeft: `4px solid ${
          hasValidation
            ? error
              ? colors.warning
              : colors.success
            : colors.selected
        }`,
      }}
    >
      {children}
    </Box>
  );
};
