import { NavLink, useParams } from "react-router-dom";
import useFetch from "../../../api/useFetch";
import Header from "../../../components/special/Header";
import { useTranslation } from "react-i18next";
import { ProjectManagementWorkPackageSkeleton } from "./ProjectManagementWorkPackageSkeleton";
import CardGrid from "../../../components/cards/layout/CardGrid";
import CardContainer from "../../../components/cards/layout/CardContainer";
import RenderHTML from "../../../components/preview/RenderHTML";
import { ProjectUserStack } from "../../../components/exo/project/ProjectUserStack";
import { Box, Breadcrumbs, Typography, useTheme } from "@mui/material";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { tokens } from "../../../global/theme/tokens";
import { useTimeTracking } from "../../../components/time-tracking/AppTimeProvider";
import { renderHours } from "../../time-tracking/worktime";
import { WPEMap } from "./work-package-elements/WPEMap";
import { createElement, useEffect, useRef, useState } from "react";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import { API_DELETE, API_PATCH, DBRequest, useConfig } from "../../../api/api";
import { DragDropContext } from "react-beautiful-dnd";

export const ProjectManagementWorkPackage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();
  const config = useConfig();

  const { clockIn, clockOut, activeProject, secondsIn } = useTimeTracking();

  const { pid, wid } = useParams();
  const { apiData, setApiData } = useFetch(`work-packages/${wid}`);
  const [project, setProject] = useState(null);

  const [isDragging, setIsDragging] = useState(false);

  const refs = useRef({});

  useEffect(() => {
    DBRequest({
      config,
      path: `projects/${pid}`,
      onResponse: (res) => setProject(res.data),
    });
  }, [config]);

  if (!apiData) return <ProjectManagementWorkPackageSkeleton />;

  const workPackage = apiData.data;

  const activeWorkpackage =
    activeProject &&
    activeProject.activeWorkPackage &&
    activeProject.activeWorkPackage.id === workPackage.id
      ? {
          ...activeProject.activeWorkPackage,
          totalTimeWorkedSeconds: secondsIn
            ? activeProject.activeWorkPackage.totalTimeWorkedSeconds + secondsIn
            : activeProject.activeWorkPackage.totalTimeWorkedSeconds,
        }
      : null;

  const workPercentage = activeWorkpackage
    ? activeWorkpackage.totalTimeWorkedSeconds / activeWorkpackage.estimatedTime
    : workPackage.totalTimeWorkedSeconds / workPackage.estimatedTime;

  // changes
  function handleChange(updatedElement) {
    const newElements = apiData.data.elements.map((obj) =>
      obj.id === updatedElement.id ? updatedElement : obj
    );
    setApiData({
      ...apiData,
      data: { ...apiData.data, elements: newElements },
    });
  }

  const handleOnDragStart = (result) => {
    setIsDragging(true);
  };

  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    // If item was dropped outside of a droppable, do nothing
    if (!destination) {
      setIsDragging(false);
      return;
    }

    const taskLists = {};
    Object.keys(refs).forEach((el) => {
      if (el != "current") {
        taskLists[el] = refs[el].apiData?.data.tasks;
      }
    });

    const taskId = Number(result.draggableId);

    let task = null;
    Object.keys(taskLists).forEach((el) => {
      taskLists[el].forEach((taskItem) => {
        if (taskItem.id === taskId) {
          task = { ...taskItem, wpId: el };
        }
      });
    });

    if (destination.droppableId.includes("trash")) {
      handleDelete(task, taskLists);
    }

    if (destination.droppableId.includes("tasks")) {
      if (
        destination.droppableId === source.droppableId &&
        destination.index === source.index
      )
        return;

      const destWpeId = Number(destination.droppableId.split(" ")[1]);

      let destSortIndex;
      if (taskLists[destWpeId].length > 0) {
        if (taskLists[destWpeId].length === destination.index) {
          destSortIndex =
            taskLists[destWpeId][destination.index - 1].sortIndex + 1;
        } else {
          destSortIndex = taskLists[destWpeId][destination.index].sortIndex;
        }
      } else {
        destSortIndex = 0;
      }

      DBRequest({
        config,
        path: `work-packages/elements/list/tasks/${task.id}/move`,
        method: API_PATCH({ sortIndex: destSortIndex, listId: destWpeId }),
        onResponse: () => {},
      });

      //Shift source list
      let updatedSourceList = taskLists[task.wpId];
      updatedSourceList = updatedSourceList.map((el) => ({
        ...el,
        sortIndex:
          el.sortIndex > task.sortIndex ? el.sortIndex - 1 : el.sortIndex,
      }));

      //remove task from source List
      updatedSourceList = updatedSourceList.filter((el) => el.id !== task.id);

      //Set destination List
      let updatedDestList = null;
      if (destination.droppableId === source.droppableId) {
        updatedDestList = updatedSourceList;
      } else {
        updatedDestList = taskLists[destWpeId];
      }

      //shift destination List
      updatedDestList = updatedDestList.map((el) => ({
        ...el,
        sortIndex:
          el.sortIndex >= destSortIndex ? el.sortIndex + 1 : el.sortIndex,
      }));

      //add task to destinationList
      updatedDestList.push({ ...task, sortIndex: destSortIndex });

      if (destination.droppableId !== source.droppableId) {
        //apply source List
        updatedSourceList = updatedSourceList.sort(
          (a, b) => a.sortIndex - b.sortIndex
        );

        refs[task.wpId].setApiData({
          ...refs[task.wpId].apiData,
          data: {
            ...refs[task.wpId].apiData.data,
            tasks: updatedSourceList,
          },
        });
      }

      //apply destination list
      updatedDestList = updatedDestList.sort(
        (a, b) => a.sortIndex - b.sortIndex
      );

      refs[destWpeId].setApiData({
        ...refs[destWpeId].apiData,
        data: { ...refs[destWpeId].apiData.data, tasks: updatedDestList },
      });
    }

    setIsDragging(false);
  };

  function handleDelete(task, taskLists) {
    DBRequest({
      config,
      path: `work-packages/elements/list/tasks/${task.id}`,
      method: API_DELETE,
      onResponse: () => {},
    });
    let updatedTasks = taskLists[task.wpId].filter((el) => el.id !== task.id);
    updatedTasks = updatedTasks.map((el) => ({
      ...el,
      sortIndex:
        el.sortIndex > task.sortIndex ? el.sortIndex - 1 : el.sortIndex,
    }));

    refs[task.wpId].setApiData({
      ...refs[task.wpId].apiData,
      data: {
        ...refs[task.wpId].apiData.data,

        tasks: updatedTasks,
      },
    });
  }

  return (
    <Box>
      <Breadcrumbs aria-label="breadcrumb">
        <NavLink
          to={"/projects"}
          underline="hover"
          className="flex items-center"
          color="inherit"
        >
          <AccountTreeIcon className="mr-2" fontSize="inherit" />
          {t("Projects")}
        </NavLink>
        <NavLink
          to={`/projects/management/${pid}`}
          underline="hover"
          className="flex items-center"
          color="inherit"
        >
          {project && project.image && (
            <img
              className=" h-5 w-5 object-cover mr-2 rounded-full"
              src={project.image.temporaryUrl}
            />
          )}
          {project && project.name}
        </NavLink>
        <Typography
          sx={{ color: "text.primary" }}
          className="flex items-center"
        >
          {apiData && apiData.data.icon && (
            <img
              className=" h-7 w-7 object-cover rounded-full"
              src={apiData.data.icon.temporaryUrl}
            />
          )}
          {apiData && apiData.data.title}
        </Typography>
      </Breadcrumbs>
      <Header title={workPackage.title} />
      <CardGrid>
        <CardContainer>
          {workPackage.estimatedTime && (
            <Box sx={{ maxWidth: "80px" }}>
              <CircularProgressbarWithChildren
                value={workPercentage}
                maxValue={1}
                styles={{
                  path: {
                    stroke:
                      workPercentage < 1
                        ? colors.primary[500]
                        : colors.redAccent[500],
                  },
                  trail: {
                    stroke: "rgba(0, 0, 0, 0.2)",
                    strokeLinecap: "butt",
                    transform: "rotate(0.25turn)",
                    transformOrigin: "center center",
                  },
                }}
              >
                <Typography variant="h4">
                  {Math.round(workPercentage * 100)}%
                </Typography>
              </CircularProgressbarWithChildren>
            </Box>
          )}
          <Box className="flex flex-col items-center">
            <Typography variant="h6" className="pt-2 uppercase">
              {t("Worked")}
            </Typography>
            <Typography variant="h4">
              {renderHours(
                (activeWorkpackage
                  ? activeWorkpackage.totalTimeWorkedSeconds
                  : workPackage.totalTimeWorkedSeconds) / 60
              )}
              h
            </Typography>
            <Typography variant="h4"></Typography>
          </Box>
        </CardContainer>
        <CardContainer span={2}>
          <RenderHTML HTML={workPackage.description} />
        </CardContainer>
        <CardContainer>
          <ProjectUserStack
            workPackage={workPackage}
            users={workPackage.users}
          />
        </CardContainer>
      </CardGrid>
      <Box className="flex flex-col gap-2 pt-4 h-full">
        <DragDropContext
          onDragEnd={handleOnDragEnd}
          onDragStart={handleOnDragStart}
        >
          {workPackage.elements.map((element, index) =>
            createElement(WPEMap[element.type], {
              element,
              key: element.id,
              pid: pid,
              onChange: handleChange,
              isDragging: isDragging,
              ref: (el) => (refs[element.id] = el),
            })
          )}
        </DragDropContext>
      </Box>
    </Box>
  );
};
