import { createElement } from 'react';
import ExtendTimeCorrection from './extended-request-versions/ExtendTimeCorrection';
import ExtendTimeCompensation from './extended-request-versions/ExtendTimeCompensation';
import { ExtendVacation } from './extended-request-versions/ExtendVacation';
import { ExtendSick } from './extended-request-versions/ExtendSick';

const ExtendRequest = ({ request, onUpdate }) => {
  const activeTypes = {
    timeCorrection: ExtendTimeCorrection,
    timeCompensation: ExtendTimeCompensation,
    vacation: ExtendVacation,
    sick: ExtendSick,
  };

  return createElement(activeTypes[request.type], {
    request: request,
    onUpdate: onUpdate,
  });
};

export default ExtendRequest;
