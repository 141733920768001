import { Box, ClickAwayListener, useTheme } from "@mui/material";
import ExoForm from "../../ExoForm";
import { tokens } from "../../../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import { API_POST, DBRequest, useConfig } from "../../../../api/api";
import { useRef } from "react";

const NewWPEToDoElement = ({ className, onCancle, onSubmit, wpeId }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const ref = useRef();

  const config = useConfig();

  const fields = [
    { label: t("Title"), type: "text", key: "name", autoComplete: "off" },
  ];

  function handleSubmit() {
    const newTask = ref?.current.dataCollection;
    if (!newTask?.name) onCancle();

    DBRequest({
      config,
      path: `work-packages/elements/list/tasks`,
      method: API_POST({ ...newTask, status: 0, wpeId, sortIndex: 0 }),
      onResponse: onSubmit,
    });
  }

  return (
    <ClickAwayListener onClickAway={handleSubmit}>
      <Box
        className={`flex items-center gap-2 rounded-lg px-3 py-1 w-full ${className}`}
        sx={{
          backgroundColor: colors.bgInput,
          border: "1px solid transparent",
        }}
      >
        <ExoForm
          ref={ref}
          fields={fields}
          noControls={true}
          onCancle={onCancle}
          onSubmit={handleSubmit}
        />
      </Box>
    </ClickAwayListener>
  );
};

export default NewWPEToDoElement;
