import { Box, Button, Dialog } from "@mui/material";
import BankAccountsSlider from "./BankAccounsSlider";
import { useEffect, useRef, useState } from "react";
import Transactions from "./Transactions";
import Header from "../../components/special/Header";
import NewBankAccount from "../../components/new/NewBankAccount";
import { API_GET, DBRequest, useConfig } from "../../api/api";
import AddCardIcon from "@mui/icons-material/AddCard";
import { useTranslation } from "react-i18next";
import ExoDialog from "../../components/exo/ExoDialog";
import useFetch from "../../api/useFetch";
import ExoBankAccounts from "../../components/exo/ExoBankAccounts";
import ExoAlert from "../../components/exo/ExoAlert";
import { updateOrCreate } from "../../components/special/updateOrCreate";
import BankAccountSelector from "./BankAccountSelector";

const BankAccounts = () => {
  const { t } = useTranslation();
  const scrollContainerRef = useRef(null);
  const config = useConfig();
  const { apiData, setApiData } = useFetch("bankaccounts");

  const handleBankUpdate = (bank) => {
    setActiveBankAccount(bank);
  };

  const [activeBankAccount, setActiveBankAccount] = useState(null);

  // first Account
  function handleCreatedAccount(newBankAccount) {
    setApiData({
      ...apiData,
      data: [...apiData.data, newBankAccount],
    });
  }
  const [open, setOpen] = useState(false);

  // reauthentification for outdated onlinebanking
  const [accountsToReauthenticate, setAccountsToReauthenticate] =
    useState(null);

  const [openNote, setOpenNote] = useState(null);

  useEffect(() => {
    if (activeBankAccount && Boolean(activeBankAccount.actionRequired))
      setOpenNote(true);
  }, [activeBankAccount]);

  function handleClose() {
    setAccountsToReauthenticate(false);
    DBRequest({ config, path: "bankaccounts", onResponse: setApiData });
  }

  function startReauth() {
    setAccountsToReauthenticate(activeBankAccount);
  }

  function handleCloseNote() {
    setOpenNote(false);
    const newAccount = { ...activeBankAccount, actionRequired: false };
    setApiData(updateOrCreate(apiData, newAccount));
  }

  return (
    <Box className="justify-start">
      <Header title={t("Bank Accounts")} />
      <BankAccountSelector
        handleCreatedAccount={handleCreatedAccount}
        handleBankUpdate={handleBankUpdate}
        accounts={apiData?.data}
      />
      <ExoDialog open={open} limitWidth>
        <NewBankAccount
          onClose={() => setOpen(false)}
          onCreate={handleCreatedAccount}
        />
      </ExoDialog>
      <ExoAlert
        onSubmit={startReauth}
        open={openNote ?? false}
        onClose={handleCloseNote}
        alert={{
          type: "warning",
          message: t("Access to online banking must be verified again."),
          cancleLabel: t("Remind me later"),
          submitLabel: t("Re-verify now"),
        }}
      />
      <ExoDialog
        open={Boolean(accountsToReauthenticate)}
        onClose={() => setAccountsToReauthenticate(false)}
      >
        {accountsToReauthenticate && (
          <ExoBankAccounts
            onClose={handleClose}
            webformApi={`bankaccount/${accountsToReauthenticate.id}/reauthenticate-webform`}
          />
        )}
      </ExoDialog>
    </Box>
  );
};

export default BankAccounts;
