import { Box, Divider, Typography } from "@mui/material";
import { t } from "i18next";
import CardContainer from "../layout/CardContainer";
import MailLogElement from "./MailLogElement";

const MailLogCard = ({ span, logs }) => {
  return logs?.length > 0 ? (
    <CardContainer className="max-h-[200px] min-h-[85px]" span={span}>
      <Box className="flex flex-col gap-2 w-full h-[95%] overflow-auto overflow-x-hidden">
        {logs.map((log, index) => (
          <Box key={log.id + "Box"}>
            <MailLogElement key={log.id + "MailLogElemement"} log={log} />
            {logs.length - 1 != index && <Divider key={log.id + "divider"} />}
          </Box>
        ))}
      </Box>
    </CardContainer>
  ) : (
    <CardContainer span={span}>
      <Typography>{t("No Mail Logs found")}</Typography>
    </CardContainer>
  );
};

export default MailLogCard;
