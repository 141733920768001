import { useNavigate, useParams } from "react-router-dom";
import { Box } from "@mui/material";
import ExtendLetter from "./ExtendLetter";
import ExoDialog from "../../components/exo/ExoDialog";
import { NewLetter } from "../../components/new/NewLetter";
import { useRef, useState } from "react";
export const ShowLetter = () => {
  const { id } = useParams();

  const extendRef = useRef(null);

  const navigate = useNavigate();

  const [edit, setEdit] = useState(null);

  return (
    <Box className="pt-0 md:pt-12 w-full h-full flex flex-col">
      <ExtendLetter
        ref={extendRef}
        row={{ id, onUpdate: () => {}, onDelete: () => {} }}
        onDelete={() => navigate("/letters")}
        onEdit={() => {
          setEdit(extendRef.current.apiData.data);
        }}
      />
      <ExoDialog open={Boolean(edit)}>
        <NewLetter
          letters={edit}
          //setLetters={extendRef.current?.setApiData}
          startData={edit}
          onClose={() => {
            setEdit(null);
          }}
        />
      </ExoDialog>
    </Box>
  );
};
