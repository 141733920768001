import {
  Box,
  useTheme,
  Typography,
  Divider,
  IconButton,
  Button,
} from '@mui/material';
import { tokens } from '../../../global/theme/tokens';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import uuid from 'react-uuid';
import { updateOrCreate } from '../../special/updateOrCreate';
import ExoDialog from '../../exo/ExoDialog';
import ExoForm from '../../exo/ExoForm';
import LabeledBox from '../../special/LabeledBox';
import MoneyLabel from '../../label/MoneyLabel';
import ExoCostCenter, { costCenterIconMap } from '../../exo/ExoCostCenter';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ProjectAddElementButton } from '../../exo/project/ProjectAddElementButton';
import * as Yup from 'yup';
import RenderHTML from '../../preview/RenderHTML';
import DeleteIcon from '@mui/icons-material/Delete';
import PDFPreview from '../../special/PDFPreview';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

const TypeReceiptPositions = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  onPreventEnter,
  param,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  const [selectedElement, setSelectedElement] = useState(null);
  const [totalSum, setTotalSum] = useState(0);

  useEffect(() => {
    if (dataCollection[field.key]) checkAndAddUUIDS(dataCollection[field.key]);
  }, []);

  function checkAndAddUUIDS(dataToCheck) {
    var dataWithUUIDS = dataToCheck;
    dataWithUUIDS.forEach((element) => {
      if (!element.id) element.id = uuid();
    });

    updateValidationOnChange(field.key, dataWithUUIDS);
  }

  const fields = [
    {
      label: t('Position'),
      key: 'position',
      type: 'text',
      colspan: '2',
    },
    {
      label: t('Cost Center'),
      type: 'costCenter',
      key: 'costCenter',
      colspan: '2',
    },
    {
      label: t('Tax Account'),
      key: 'taxAssignment',
      type: 'taxAssignment',
    },
    {
      label: t('Tax Rate'),
      key: 'taxRate',
      type: 'text',
      unit: '%',
    },
    {
      label: t('Price'),
      key: 'amount',
      type: 'money',
      after: CustomFieldAfter,
    },
    {
      label: `${t('Comment')} (${t('optional')})`,
      key: 'note',
      type: 'richText',
    },
  ];

  const formValidationSchema = Yup.object().shape({
    amount: Yup.string().required(t('Amount is required')),
    position: Yup.string().required(t('Position is required')),
    taxAssignment: Yup.object().shape({
      id: Yup.number().required(t('Tax Assignment is required')),
    }),
    taxRate: Yup.number().required(t('Tax Rate is required')),
    costCenter: Yup.object().shape({
      id: Yup.string().required(t('Cost Center is required')),
    }),
  });

  function handleChange(data) {
    setSelectedElement(null);

    const newData = updateOrCreate(dataCollection[field.key] || [], data);

    updateValidationOnChange(field.key, newData);
  }

  function handleAddElement() {
    setSelectedElement({ id: uuid(), taxRate: 19 });
  }

  function handleDeleteElement() {
    const newData = dataCollection[field.key].filter(
      (obj) => obj.id !== selectedElement.id
    );

    updateValidationOnChange(field.key, newData);
    setSelectedElement(null);
  }

  const hasFile =
    param && param.file && Boolean(Object.keys(param.file).length);

  const fileData = param ? param.file : {};

  useEffect(() => {
    if (!dataCollection[field.key]) return;
    //calc total sum of all positions
    var newSum = 0;
    dataCollection[field.key].forEach((pos) => {
      newSum += parseFloat(pos.amount);
    });

    setTotalSum(newSum);
  }, [dataCollection[field.key]]);

  return (
    <Box
      className="w-full flex flex-col gap-4"
      sx={{
        gridColumn: field.colspan
          ? `span ${field.colspan} / span ${field.colspan}`
          : 'unset',
      }}
    >
      <Box className="flex justify-between items-center">
        <Typography variant="h4" className="pl-2">
          {t('Distribution')}
        </Typography>
        <Typography
          variant="h4"
          fontWeight={600}
          color={totalSum > 0 ? 'error' : 'success'}
          className="pr-4"
        >
          <Typography variant="h6">{t('Sum')}</Typography>
          <MoneyLabel money={totalSum} />
        </Typography>
      </Box>

      {dataCollection[field.key] &&
        dataCollection[field.key].map((element) => (
          <ReceiptPosition
            key={element.id}
            element={element}
            onClick={setSelectedElement}
            validationSchema={formValidationSchema}
          />
        ))}

      <ProjectAddElementButton
        label={t('add distribution')}
        onClick={handleAddElement}
      />

      <ExoDialog
        open={Boolean(selectedElement)}
        className={`flex flex-col-reverse lg:flex-row gap-2 h-full overflow-hidden ${
          hasFile ? '' : 'justify-center'
        }`}
      >
        {hasFile && (
          <>
            {fileData.type === 'application/pdf' ? (
              <PDFPreview
                src={
                  fileData.id ? fileData.path : URL.createObjectURL(fileData)
                }
              />
            ) : (
              <img
                className="w-full h-full object-contain"
                src={
                  fileData.id ? fileData.path : URL.createObjectURL(fileData)
                }
                alt={fileData.title}
              />
            )}
          </>
        )}
        <ExoForm
          header={t('Distribution')}
          outerClassName="flex flex-col gap-4 relative h-full"
          className="max-h-full flex flex-col overflow-y-auto gap-4 pt-2 max-w-full min-w-[unset] w-full lg:min-w-[500px] lg:max-w-[550px]"
          fields={fields}
          validationSchema={formValidationSchema}
          startDataCollection={selectedElement}
          onSubmit={handleChange}
          onCancle={() => setSelectedElement(null)}
          noDifferences
        />
        <IconButton
          onClick={handleDeleteElement}
          color="error"
          sx={{ position: 'absolute' }}
          className="top-5 right-5"
        >
          <DeleteIcon />
        </IconButton>
      </ExoDialog>
    </Box>
  );
};

export const ReceiptPosition = ({
  element,
  onClick,
  noBackground,
  validationSchema,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);

  const { t } = useTranslation();
  const { taxAssignment, costCenter, amount, position, taxRate, note } =
    element;

  const [errors, setErrors] = useState(false);

  useEffect(() => {
    validateData();
  }, [element]);

  // Function to validate dataCollection
  const validateData = async () => {
    try {
      // Validate the dataCollection
      await validationSchema.validate(element, { abortEarly: false });
      // If validation passes, update isValid to true
      setErrors(null);
    } catch (err) {
      // If validation fails, update isValid to false
      setErrors(err.errors);
      console.error('Validation errors:', err.errors);
    }
  };

  return (
    <LabeledBox
      className="flex flex-col @container gap-4 w-full"
      onClick={() => onClick(element)}
      noBackground={noBackground}
      sx={{
        border: errors ? `2px solid ${colors.warning}` : 'unset',
      }}
    >
      <Box className="flex justify-between flex-col @md:flex-row @md:gap-6">
        <Box className="flex gap-4 items-center justify-between @md:justify-start">
          <Box>
            {errors && (
              <Box className="flex flex-col gap-1">
                {errors?.map((obj) => (
                  <Typography fontSize={'13px'} color={colors.warning}>
                    {obj}
                  </Typography>
                ))}
              </Box>
            )}
            <Typography fontWeight={600}>{position}</Typography>
            {taxAssignment && (
              <Typography
                className="monospace"
                variant="h6"
              >{`${taxAssignment.account} ${taxAssignment.label}`}</Typography>
            )}
          </Box>

          <ArrowForwardIcon className=" opacity-40" />

          {costCenter && (
            <Box className="flex flex-col justify-center items-center">
              {costCenterIconMap[costCenter.type]}
              <Typography>{costCenter.name}</Typography>
            </Box>
          )}
        </Box>
        <Box>
          <Typography
            fontWeight={600}
            variant="h4"
            color={parseFloat(amount) > 0 ? 'error' : 'success'}
          >
            <MoneyLabel money={amount} />
          </Typography>
          <Typography
            variant="h6"
            className="monospace"
          >{`${taxRate} %`}</Typography>
        </Box>
      </Box>
      {note && (
        <>
          <Divider />
          <Box>
            <Typography variant="h6">{t('Note')}</Typography>
            <RenderHTML HTML={note || ' '} />
          </Box>
        </>
      )}
    </LabeledBox>
  );
};

export const CustomFieldAfter = ({
  updateValidationOnChange,
  dataCollection,
  errors,
  field,
  onPreventEnter,
  param,
}) => {
  const { t } = useTranslation();

  function handleAdd() {
    const taxRate = dataCollection.taxRate;
    const amount = dataCollection[field.key];

    const newAmount = amount * (1 + taxRate / 100);
    updateValidationOnChange(field.key, newAmount);
  }

  function handleRemove() {
    const taxRate = dataCollection.taxRate;
    const amount = dataCollection[field.key];

    const newAmount = amount / (1 + taxRate / 100);
    updateValidationOnChange(field.key, newAmount);
  }

  if (!dataCollection[field.key]) return;

  return (
    <Box className="flex justify-between">
      <Button
        color="warning"
        variant="outlined"
        onClick={handleRemove}
        endIcon={<RemoveIcon />}
        size="small"
      >
        {t('Remove tax')}
      </Button>
      <Button
        color="info"
        variant="outlined"
        onClick={handleAdd}
        endIcon={<AddIcon />}
        size="small"
      >
        {t('Add tax')}
      </Button>
    </Box>
  );
};

export default TypeReceiptPositions;
