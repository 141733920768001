import { Box, Typography, useTheme } from "@mui/material";
import { BankCard } from "./BankCard";
import { tokens } from "../../global/theme/tokens";
import { useTranslation } from "react-i18next";
import MoneyLabel from "../../components/label/MoneyLabel";
import WifiOffIcon from "@mui/icons-material/WifiOff";
import WifiIcon from "@mui/icons-material/Wifi";

const BankAccount = ({ bank, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode, theme.palette.colorTheme);
  const { t } = useTranslation();

  return (
    <Box
      className="w-full h-full rounded-lg backdrop-blur-lg transition-transform overflow-hidden cursor-pointer"
      onClick={onClick}
      sx={{
        //backgroundColor: colors.card,
        backgroundColor: colors.glass,
        ":hover": {
          transform: "scale(1.01)",
        },
      }}
    >
      <Box
        className="w-[10px] h-full absolute rounded-l-lg"
        sx={{ background: bank.color }}
      />
      <Box className={`w-full h-full flex justify-between p-3 px-5`}>
        {/*<BankCard bank={bank} />*/}
        <Box>
          <Typography variant="h4">{bank.iban}</Typography>
          <Typography variant="h5">{bank.bankName}</Typography>
          <Typography variant="h5">{bank.accountHolder}</Typography>
        </Box>
        <Box className="flex flex-col justify-between items-end">
          <Typography
            noWrap
            variant="h5"
            textAlign="right"
            fontWeight={600}
            sx={{
              minWidth: "100px",
              color: bank.balance >= 0 ? colors.success : colors.error,
            }}
          >
            <MoneyLabel money={bank.balance} />
          </Typography>
          {bank.connected ? (
            <WifiIcon
              width={"100%"}
              color={bank.connected ? "success" : "error"}
            />
          ) : (
            <WifiOffIcon
              width={"100%"}
              color={bank.connected ? "success" : "error"}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BankAccount;
